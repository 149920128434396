
import { Box, Button, Modal, Paper, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import React from "react";
import CDN_URL from "../utlis/CDN_URL";



export function UserContactCard({ address }: any) {
    const addressFields = [
        { key: "first_name", label: "First Name" },
        { key: "middle_name", label: "Middle Name" },
        { key: "last_name", label: "Last Name" },
        { key: "email", label: "Email" },
        { key: "phone", label: "Phone" },
        { key: "company", label: "Company" },
        { key: "address1", label: "Address 1", span: 2 }, // Spanning half the row
        { key: "address2", label: "Address 2", span: 2 }, // Spanning half the row
        { key: "city", label: "City" },
        
        { key: "state", label: "State" },
        { key: "pincode", label: "Pincode" },
        { key: "country", label: "Country" },
      ];
      
      
    return (
        <>
       
        <Box
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: "repeat(3, 1fr)", // 4 equal-width columns
        width:"100%",
        margin: "0 auto",
        padding: 2,
        borderRadius: 2,
        //border: "1px solid #e6ebeb",
        // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        // backgroundColor: "#f9f9f9",
      }}
    >
        {addressFields?.slice(0,6)?.map((field, index) => {
           

            
            return (
                <Box
                key={index}
                sx={{
                    gridColumn: field.span ? `span ${field.span}` : "span 1", // Address1 and Address2 span 2 columns
                  }}
              >
                <TextField
                  label={field.label}
                  defaultValue={address?.[field.key] || "--"} // Fallback to "N/A" for missing values
                  InputProps={{
                    readOnly: true,
                    sx: {
                      height: "2.8rem", // Adjust height
                      padding: "2rem 0rem", // Add padding
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "1rem", // Adjust font size for label
                    },
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                //   sx={{padding:"0.5rem"}}
                />
              </Box>
            )
        })}

    
      </Box>
      <Box
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: "repeat(2, 1fr)", // 4 equal-width columns
        width:"100%",
        margin: "0 auto",
        padding: 2,
        borderRadius: 2,
        //border: "1px solid #e6ebeb",
        // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        // backgroundColor: "#f9f9f9",
      }}
    >
        {addressFields?.slice(6,8)?.map((field, index) => {
            console.log(field.key,address?.[field.key], "orders");

            
            return (
                <Box
                key={index}
                // sx={{
                //     gridColumn: field.span ? `span ${field.span}` : "span 1", // Address1 and Address2 span 2 columns
                //   }}
              >
                <TextField
                  label={field.label}
                  defaultValue={address?.[field.key] || "--"} // Fallback to "N/A" for missing values
                  InputProps={{
                    readOnly: true,
                    sx: {
                      height: "2.8rem", // Adjust height
                      padding: "2rem 0rem", // Add padding
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "1rem", // Adjust font size for label
                    },
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                //   sx={{padding:"0.5rem"}}
                />
              </Box>
            )
        })}

    
      </Box>
      <Box
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: "repeat(3, 1fr)", // 4 equal-width columns
        width:"100%",
        margin: "0 auto",
        padding: 2,
        borderRadius: 2,
        //border: "1px solid #e6ebeb",
        // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        // backgroundColor: "#f9f9f9",
      }}
    >
        {addressFields?.slice(8,11)?.map((field, index) => {
            console.log(field.key,address?.[field.key], "orders");

            
            return (
                <Box
                key={index}
                sx={{
                    gridColumn: field.span ? `span ${field.span}` : "span 1", // Address1 and Address2 span 2 columns
                  }}
              >
                <TextField
                  label={field.label}
                  defaultValue={address?.[field.key] || "--"} // Fallback to "N/A" for missing values
                  InputProps={{
                    readOnly: true,
                    sx: {
                      height: "2.8rem", // Adjust height
                      padding: "2rem 0rem", // Add padding
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "1rem", // Adjust font size for label
                    },
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                //   sx={{padding:"0.5rem"}}
                />
              </Box>
            )
        })}

    
      </Box>
      </>
    );
  }

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80vw", md: "80vw" },
  // height:"80vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

function ProductDetails({ parts, isOpen, onCloseModal, status, address }: any) {
    const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      width: 80,
      sortable: true,
      renderCell: (params) => params.value ?? "--", // Display "--" for null, undefined, or empty values
    },

    {
      field: "name",
      headerName: "Product",
      width: 390,
      // type: "date",
      // sortable: true,

      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", gap: "1rem", alignItems: "center", cursor:"pointer" }} onClick={() => {
            window.open(
              `/partdetails/${params?.row?.route}/${params?.row?.vault}/?part_id=${params?.row?.vault}`,
              "_blank"
            );
          }}>
            <img
              src={`${CDN_URL}${params?.row?.image}`}
              style={{ height: "8rem", width: "8rem" }}
              alt=""
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  marginBottom: "0.5rem",
                  fontSize: "0.9rem",
                  fontWeight: "600",
                }}
              >
                {" "}
                {params?.row?.name}
              </Typography>
              <Typography sx={{ fontSize: "0.9rem" }}>
                {" "}
                {params?.row?.supplier}
              </Typography>
            </Box>
          </Box>
        );
      },
    },

    {
      field: "date",
      headerName: "Ordered on",
      width: 220,
      type: "date",
      sortable: true,
      valueGetter: (params) => new Date(params.value), // Convert date string to Date object
      renderCell: (params) => {
        console.log(params.value, "payment time orders");
        const dateValue =
          params.value instanceof Date && !isNaN(params.value.getTime())
            ? params.value.toLocaleDateString()
            : "--"; // Display "--" if date is invalid or not provided
        return <>{moment(params?.value).format("Do MMM h:mm A")}</>;
      },
    },

        // {
    //   field: "paymentMode",
    //   headerName: "Payment Mode",
    //   width: 150,
    //   renderCell: (params) => params.value ?? "--", // Display "--" for null, empty, or undefined values
    // },

    {
        field: "quantity",
        headerName: "Quantity",
        width: 180,
        renderCell: (params) =>
          params.row?.quantity != null && params.row?.quantity !== "" ? `${params.row?.quantity}` : "--", // Display "--" for null, empty, or undefined values
      },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      renderCell: (params) =>
        params.value != null &&
        params.value !== "" &&
        params.value !== undefined
          ? `₹ ${params.value}`
          : "--", // Display "--" for null, empty, or undefined values
    },



    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 150,
    //   renderCell: (params) => {
    //     return (
    //       <Box
    //         sx={{ textDecoration: "underline", cursor: "pointer" }}
    //         // onClick={()=> statusModal.open({
    //         //     item:params?.row
    //         // })}
    //       >
    //         View Status
    //       </Box>
    //     );
    //   }, // Display "--" for null, empty, or undefined values
    // },

    // {
    //     field: 'invoice',
    //     headerName: 'Invoice',
    //     description: 'This column has a value getter and is not sortable.',
    //     sortable: false,
    //     width: 60,
    //     renderCell: (params) => (
    //         <div>
    //             <DownloadIcon
    //                 onClick={() => onInvoiceDownloadClick(params.row?.invoice)}
    //                 style={{ cursor: 'pointer', color:"#00887a" }}
    //             />
    //         </div>
    //     ),
    // },
  ];

  const rows =
    parts &&
    parts?.map((item: any, index: number) => {
      return {
        id: index + 1,
        order_id: index,
        vault:item?.vault_details?.id,
        route:item?.vault_details?.route_name,
        name: item?.vault_details?.name,
        image: item?.part_images?.[0],
        amount: item?.amount,
        supplier: item?.supplier_details?.name,
        quantity: item?.quantity,
        date: status?.[0]?.date,
        status: status,
        paymentMode: item?.paymentMode,
        currentStage: status?.length,
      };
    });
      
    
  return (
    <Modal open={isOpen} >
      <Box sx={style}>
        <Box>
        
          <Box
            sx={{
              height: "65vh",
              flexGrow: 1,
              width: "100%",
              "& .super-app-theme--completed": {
                bgcolor: "#00887a4d",
                // color: "#4646d3",
              },
              "& .super-app-theme--in_progress": {
                bgcolor: "#ffffff",
                // color: "#4646d3",
              },
              "& .super-app-theme--selected": {
                bgcolor: "#b4ddde",
                // color: "#4646d3",
              },
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
          
            <DataGrid
              getRowHeight={() => 96}
              rows={rows}
              columns={columns}
            //   initialState={{
            //     pagination: {
            //         paginationState: {
            //         pageSize: 20,
            //       },
            //     },
            //   }}
            //   pageSizeOptions={[5]}
            //   disableRowSelectionOnClick
            //   columnHeaderHeight={38}
              rowHeight={38}
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#e8ebe9", // Light grey background for the header
                },

                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottomColor: "#cacccb", // Light grey border for cells
                },
                "& ::-webkit-scrollbar": {
                  width: "0.3rem",
                  height: "0.3rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#045DE9",
                },
                "& ::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3",
                },
              }}
            />
    
     
  
      
    
     

            
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop:"0.5rem" }}>
            <Button variant="contained" onClick={onCloseModal}>Close</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default ProductDetails;
