import { Box, Divider, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",

  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 3,
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
};

function UserAddressModal({ open, onCloseModal, address }: any) {
  console.log(address);

  const addressFields = [
    { key: "first_name", label: "First Name" },
    { key: "middle_name", label: "Middle Name" },
    { key: "last_name", label: "Last Name" },
    { key: "email", label: "Email" },
    { key: "phone", label: "Phone" },
    { key: "company", label: "Company" },
    { key: "address1", label: "Address 1", span: 2 }, // Spanning half the row
    { key: "address2", label: "Address 2", span: 2 }, // Spanning half the row
    { key: "city", label: "City" },

    { key: "state", label: "State" },
    { key: "pincode", label: "Pincode" },
    { key: "country", label: "Country" },
  ];

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Delivery Address
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
        <>
          <Box
            sx={{
              display: "grid",
              gap: 2,
              gridTemplateColumns: "repeat(3, 1fr)", // 4 equal-width columns
              width: "100%",
              margin: "0 auto",
              padding: 2,
              borderRadius: 2,
              //border: "1px solid #e6ebeb",
              // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              // backgroundColor: "#f9f9f9",
            }}
          >
            {addressFields?.slice(0, 6)?.map((field, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    gridColumn: field.span ? `span ${field.span}` : "span 1", // Address1 and Address2 span 2 columns
                  }}
                >
                  <TextField
                    label={field.label}
                    defaultValue={address?.[field.key] || "--"} // Fallback to "N/A" for missing values
                    InputProps={{
                      readOnly: true,
                      sx: {
                        height: "2.8rem", // Adjust height
                        padding: "2rem 0rem", // Add padding
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "1rem", // Adjust font size for label
                      },
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    //   sx={{padding:"0.5rem"}}
                  />
                </Box>
              );
            })}
          </Box>
          <Box
            sx={{
              display: "grid",
              gap: 2,
              gridTemplateColumns: "repeat(2, 1fr)", // 4 equal-width columns
              width: "100%",
              margin: "0 auto",
              padding: 2,
              borderRadius: 2,
              //border: "1px solid #e6ebeb",
              // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              // backgroundColor: "#f9f9f9",
            }}
          >
            {addressFields?.slice(6, 8)?.map((field, index) => {
              console.log(field.key, address?.[field.key], "orders");

              return (
                <Box
                  key={index}
                  // sx={{
                  //     gridColumn: field.span ? `span ${field.span}` : "span 1", // Address1 and Address2 span 2 columns
                  //   }}
                >
                  <TextField
                    label={field.label}
                    defaultValue={address?.[field.key] || "--"} // Fallback to "N/A" for missing values
                    InputProps={{
                      readOnly: true,
                      sx: {
                        height: "2.8rem", // Adjust height
                        padding: "2rem 0rem", // Add padding
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "1rem", // Adjust font size for label
                      },
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    //   sx={{padding:"0.5rem"}}
                  />
                </Box>
              );
            })}
          </Box>
          <Box
            sx={{
              display: "grid",
              gap: 2,
              gridTemplateColumns: "repeat(3, 1fr)", // 4 equal-width columns
              width: "100%",
              margin: "0 auto",
              padding: 2,
              borderRadius: 2,
              //border: "1px solid #e6ebeb",
              // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              // backgroundColor: "#f9f9f9",
            }}
          >
            {addressFields?.slice(8, 11)?.map((field, index) => {
              console.log(field.key, address?.[field.key], "orders");

              return (
                <Box
                  key={index}
                  sx={{
                    gridColumn: field.span ? `span ${field.span}` : "span 1", // Address1 and Address2 span 2 columns
                  }}
                >
                  <TextField
                    label={field.label}
                    defaultValue={address?.[field.key] || "--"} // Fallback to "N/A" for missing values
                    InputProps={{
                      readOnly: true,
                      sx: {
                        height: "2.8rem", // Adjust height
                        padding: "2rem 0rem", // Add padding
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "1rem", // Adjust font size for label
                      },
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    //   sx={{padding:"0.5rem"}}
                  />
                </Box>
              );
            })}
          </Box>
        </>
      </Box>
    </Modal>
  );
}

export default UserAddressModal;
