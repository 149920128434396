import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
import CDN_URL from "../utlis/CDN_URL";


const ImageGallery: React.FC<{ message: any }> = ({ message }) => {
  const viewerRef = useRef<any>(null);
  const viewerInstanceRef = useRef<any>(null);  // Ref to store Viewer instance

  const renderedImages = message?.attachment?.filter(
    (item: any) => item?.file_type === "Image"
  );

  useEffect(() => {
    if (viewerRef.current && renderedImages?.length > 0) {
      viewerInstanceRef.current = new Viewer(viewerRef.current, {
        inline: false,
        button: true,
        navbar: true,
        title: true,
        toolbar: true,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
      });

      // Cleanup to destroy Viewer instance on unmount or update
      return () => {
        viewerInstanceRef.current?.destroy();
      };
    }
  }, [renderedImages]);

  const openImageViewer = (index: number) => {
    if (viewerInstanceRef.current) {
      viewerInstanceRef.current.view(index);
    } else {
      console.error("Viewer instance not yet initialized");
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        gap: "0.5rem",
        marginTop: "10px",
        flexWrap: "wrap",
      }}
    >
      {/* Hidden images for ViewerJS */}
      <Box ref={viewerRef} sx={{ width: 0, height: 0, overflow: "hidden" }}>
        {renderedImages?.map((image: any, index: any) => (
            <img key={index}  src={`${CDN_URL}${image?.url}`} alt={`image-${index}`} style={{width:100, height:100, display: "none"}} />
        //   <Image
        //     key={index}
        //     unoptimized
        //     src={`${CDN_URL}${image?.url}`}
        //     width={100}
        //     height={100}
        //     alt={`image-${index}`}
        //     style={{ display: "none" }}
        //   />
        ))}
      </Box>

      {/* Display first 4 images */}
      {renderedImages?.slice(0, 4).map((item: any, idx: number) => (
        <Box
          key={idx}
          component="img"
          src={`${CDN_URL}${item?.url}`}
          alt={`Attachment ${idx + 1}`}
          sx={{
            width: "9rem",
            height: "9rem",
            borderRadius: "8px",
            objectFit: "cover",
            cursor: "pointer",
          }}
          onClick={() => openImageViewer(idx)}
        />
      ))}

      {/* Show + count on the last image if more than 4 */}
      {Array.isArray(renderedImages) && renderedImages?.length > 4 && (
          <Box
          onClick={()=>  openImageViewer(0)}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              borderRadius: "8px",
              fontWeight: "bold",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
           
          >
            +{renderedImages?.length - 4}
          </Box>
        )}
    </Box>
  );
};

export default ImageGallery;
