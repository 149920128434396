import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import docIcon from "../Assets/static/Images/document-icon.png"
import imgIcon from "../Assets/static/Images/image-icon.png"
import pdfIcon from "../Assets/static/Images/pdfIcon.png"
import "./SingleChat.scss";
import {
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  Drawer,
  Paper,
  IconButton,
  Skeleton,
  styled,
} from "@mui/material";
import { useSelector } from "react-redux";
import { userSelectors } from "../Redux/Reducers/user.reducer";
import { AUTH } from "../Redux/Services/auth.service";
import { toast } from "react-toastify";
import { API, socketBase } from "../api-services";
import moment from "moment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import ImageUploadS3ProofDocs from "../DataBaseModule/Suppliers/AddBusinessPage/ImageUploadS3ProofDocs";
import CDN_URL from "../utlis/CDN_URL";
import { deepOrange } from "@mui/material/colors";
import ImageGallery from "./ImageViewer";

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: "10px",
  height: "30px",
  width: "30px",
  backgroundColor: "#808080",
  fontSize: "1rem",
  textTransform: "uppercase",
  "&.MuiAvatar-colorDefault": {
    backgroundColor: "#808080",
  },
}));

// Sample avatar image (replace with your own image URL)
const avatarSrc = "https://source.unsplash.com/random/50x50"; // Placeholder image

const MessageSkeleton = () => {
  return (
    <Box sx={{ padding: 2 }}>
      {/* Skeleton for the message content */}
      <Skeleton variant="rectangular" width="100%" height={60} />
      {/* Skeleton for the timestamp or user info */}
      <Skeleton variant="text" width="60%" sx={{ marginTop: 1 }} />
    </Box>
  );
};

const MessageFeedSkeleton = ({ count }: any) => {
  return (
    <Box>
      {Array.from(Array(count)).map((_, index) => (
        <MessageSkeleton key={index} />
      ))}
    </Box>
  );
};

function SingleChat({ handleClose, open, request, page_name, heading }: any) {
  const formik = useFormik({
    initialValues: {
      attachments: [],
    },
    onSubmit: (values) => {},
  });
  const [userLogo, setUserLogo] = useState<any>();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [attchmentLoader, setAttachmentLoader] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [roomId, setRoomId] = useState(null);
  const [chatHistory, setChatHistory] = useState<any[]>([]);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [messageContent, setMessageContent] = useState<string>("");
  const [messageContentFiles, setMessageContentFiles] = useState<any>([]);

  const fetchPreviousChats = () => {
    setFetchLoader(true);
    API.get(`chat/room/`, { room_id: request?.room_id }, 0)
      .then((res: any) => {
        if (res.data?.data && Array.isArray(res.data?.data)) {
          setChatHistory(res.data?.data);

          // router.push(`/add-business/0?claimId=${res.data?.claim_request_id}`);
        }
      })
      .catch((error: any) => {
        setFetchLoader(false);
        console.log(error.response.data[0]);
      })
      .finally(() => {
        setFetchLoader(false);
      });
  };

  const getFileType = (url: string | undefined | null): string => {
    if (!url) {
      return "unknown"; // Return "unknown" if the URL is null or undefined
    }

    const extension = url.split(".").pop()?.toLowerCase();

    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "svg":
        return "image";
      case "pdf":
        return "pdf"; // Return "pdf" for PDF files
      case "doc":
      case "docx":
      case "xls":
      case "xlsx":
      case "ppt":
      case "pptx":
      case "txt":
      case "rtf":
      case "odt":
      case "wps":
        return "document";
      case "mp4":
      case "mov":
      case "avi":
        return "video";
      case "mp3":
      case "wav":
        return "audio";
      default:
        return "unknown";
    }
  };

  const getFileName = (url: string | undefined | null): string => {
    if (!url) {
      return "Unnamed File"; // Return a default name if the URL is null or undefined
    }

    const parts = url.split("/");
    return parts[parts.length - 1] || "Unnamed File"; // Fallback to default name if empty
  };

  const fetchRoomId = () => {
    API.post("chat/room/", {
      page_type: page_name,

      object_id: request?.id,
    })
      .then((res) => {
        setRoomId(res.data.room_id);
      })
      .catch(() => {});
  };

  const handleFilesUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAttachmentLoader(true);

    const files = event.target.files;
    if (files) {
      const totalSize = Array.from(files).reduce(
        (acc, file) => acc + file.size,
        0
      );

      // Check if total size exceeds 200 MB (200 * 1024 * 1024 bytes)
      if (totalSize > 25 * 1024 * 1024) {
        Swal.fire({
          title: "Warning!",
          text: "Total file size exceeds the maximum limit of 200MB",
          icon: "warning",
          confirmButtonText: "Ok",
          customClass: {
            container: "swal2Container",
          },
          confirmButtonColor: "#008878",
        }).then((result) => {
          if (result.isConfirmed) {
            setAttachmentLoader(false);
          }
        });
        return; // Exit the function if the size exceeds the limit
      }

      let uploadedCount = 0;
      const totalFiles = files.length;

      Array.from(files).forEach((file, index) => {
        ImageUploadS3ProofDocs(
          [file],
          (fieldName: string, fileValue: any) => {
            // Call formik's setFieldValue for each file
            formik.setFieldValue(fieldName, fileValue);

            // Increment uploaded count and check if all files are uploaded
            uploadedCount += 1;
            if (uploadedCount === totalFiles) {
              setAttachmentLoader(false); // All files are uploaded
            }
          },
          setAttachmentLoader,
          `attachments.[${index}]`
        );
      });
    }
  };

  useEffect(() => {
    if (request?.room_id) {
      setRoomId(request?.room_id);
    } else {
      fetchRoomId();
    }
  }, []);

  useEffect(() => {
    console.log(AUTH && AUTH.token && roomId);

    if (AUTH && AUTH.token && roomId) {
      // Fetch previous chats on component mount
      console.log(request?.room_id);

      fetchPreviousChats();

      const params = new URLSearchParams({
        token: AUTH.token,
        user_id: AUTH.user,
        page_name: page_name,
        object_id: request?.id,
      });

      const newSocket = new WebSocket(
        `${socketBase}/chat/${roomId}/?${params.toString()}`
      );

      // const newSocket = new WebSocket(
      //   `${socketBase}/chat/?token=${AUTH.token}&user_id=${AUTH.user}&page_name=${page_name}&object_id=${request?.id}&room_name=${request?.room_name || null}`
      // );
      setSocket(newSocket);

      newSocket.onmessage = (event: MessageEvent) => {
        const data: any = JSON.parse(event.data);

        // Update chat history with the new message
        if (data?.data) {
          setChatHistory((prev) => [...prev, data?.data]);
        }
      };

      newSocket.onclose = () => {
        console.log("Chat WebSocket closed");
      };

      newSocket.onerror = (error: Event) => {
        console.error("Chat WebSocket error:", error);
      };

      // Cleanup function to close the WebSocket connection
      return () => {
        newSocket.close();
      };
    }
  }, [AUTH, roomId]);

  const images: any = [
    "https://images.pexels.com/photos/1659438/pexels-photo-1659438.jpeg",
    "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg",
    "https://images.pexels.com/photos/757889/pexels-photo-757889.jpeg",
    "https://images.pexels.com/photos/1659438/pexels-photo-1659438.jpeg",
    "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg",
    "https://images.pexels.com/photos/757889/pexels-photo-757889.jpeg",
  ];

  const userProfileDetails: any = useSelector(userSelectors.selectAll);

  const sendMessage = () => {
    if (socket && (messageContent || formik.values.attachments?.length > 0)) {
      const message: any = {
        room: request?.room_id || null,
        user_id: AUTH!.user,
        message: messageContent,
        attachment:
          formik.values.attachments?.length > 0
            ? formik.values.attachments
            : [],
      };

      console.log(JSON.stringify(message));

      // {"room": "chat_1c9c884d-cd94-4", "user_id": 58, "message": "wsedrfgthjkm", "attachment": null}

      // Send the message over the WebSocket
      socket.send(JSON.stringify(message));

      setMessageContent("");
      setMessageContentFiles([]);
      formik.setFieldValue("attachments", []);
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);
  console.log(formik.values.attachments);

  return (
    <Drawer
      anchor="right" // or "left" depending on your preference
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          width: "500px",
          height: "100%",
          border: "1px solid #e0e0e0",
          borderRadius: "10px",
          backgroundColor: "#f7f7f7",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Header with Avatar, Name, and Status */}
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Avatar
              src={
                request?.user_logo
                  ? `${CDN_URL}${request?.user_logo}`
                  : avatarSrc
              }
              sx={{ marginRight: "10px", height: "30px", width: "30px" }}
            />
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {heading}

                {page_name === "new_sc_request" ? (
                  <span style={{ color: "#008080" }}>
                    {" "}
                    {request?.part_name}{" "}
                  </span>
                ): page_name === "create_supplier" ? (
                  <span style={{ color: "#008080" }}>
                    {request?.business_name}{" "}
                  </span>
                )
                : page_name === "existing_sc_request" ? (
                  <span style={{ color: "#008080" }}>
                    {" "}
                    {request?.part_name}
                  </span>
                ): page_name === "supplier_part" ? (
                  <span style={{ color: "#008080" }}>
                    {request?.name}{" "}
                  </span>
                ): page_name === "new_supplier" ? (
                  <span style={{ color: "#008080" }}>
                    {request?.business_name}{" "}
                  </span>
                ) : page_name === "edit_supplier" ? (
                  <span style={{ color: "#008080" }}>
                    {request?.business_name}{" "}
                  </span>
                ) : page_name === "unlisted_part" ? (
                  <span style={{ color: "#008080" }}>
                    {" "}
                    {request?.part_name}{" "}
                  </span>
                ): page_name === "product_cart" ? (
                  <span style={{ color: "#008080" }}>
                    {" "}
                    {request?.orderId}{" "}
                  </span>
                )  : null}
              </Typography>
              {/* <Typography variant="body2" color="textSecondary">
            Online
          </Typography> */}
            </Box>
          </Box>
        </Box>

        {/* Chat */}
        {fetchLoader ? (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column-reverse",
              overflowY: "auto",
              padding: "10px",
            }}
          >
            <MessageFeedSkeleton count={7} /> {/* Adjust count as needed */}
          </Box>
        ) : (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              overflowY: "auto", // 'auto' works better for scrollbars when content overflows
            }}
            ref={chatContainerRef}
          >
            <Box
              sx={{
                flexGrow: 1, // Allows the content to grow and fill the remaining space
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end", // Aligns content at the bottom
              }}
            >
              {Array.isArray(chatHistory) &&
                chatHistory?.map((message: any, index: any) => {
                   console.log("message", message);

                  return (
                    <>
                      {userProfileDetails?.[0]?.id !== message?.sender ? (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            marginBottom: "20px",
                            padding: "10px",
                            maxWidth: "80%",
                          }}
                        >
                          <Avatar
                            src={
                              request?.user_logo
                                ? `${CDN_URL}${request?.user_logo}`
                                : avatarSrc
                            }
                            sx={{
                              marginRight: "10px",
                              height: "22px",
                              width: "22px",
                              "&.MuiAvatar-colorDefault": {
                                backgroundColor: "#808080", // Change default background color if needed
                              },
                            }}
                          />

                          <Box>
                            <Typography
                              variant="caption"
                              sx={{ marginBottom: "5px", color: "gray" }} // Sender's name
                            >
                              {message?.sender_username}
                            </Typography>

                            {/* <Typography
                              sx={{
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                padding: "10px",
                                boxShadow: 1,
                              }}
                            >
                              {message?.content}
                            </Typography>
                            {message?.attachment?.length > 0 && (
                                      <ImageGallery message={message} />
                                    )} */}

{(message?.content !== "" ||
                                  message?.attachment?.filter(
                                    (item: any) => item?.file_type === "Image"
                                  )?.length !== 0) && (
                                  <Box
                                    sx={{
                                      padding: "10px",
                                      backgroundColor: "#fff",
                                      borderRadius: "10px",
                                      maxWidth: "100%",
                                      boxShadow: 1,
                                    }}
                                  >
                                    <Typography sx={{}}>
                                      {message?.content}
                                    </Typography>

                                    {message?.attachment?.length > 0 && (
                                      <ImageGallery message={message} />
                                    )}
                                  </Box>
                                )}

                                {message?.attachment?.length > 0 && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "0.5rem",
                                      marginTop: "10px",
                                      flexWrap: "wrap",
                                      width: "100%",
                                    }}
                                  >
                                    {message?.attachment
                                      ?.filter(
                                        (item: any) =>
                                          item?.file_type !== "Image"
                                      )
                                      ?.map((item: any) => {
                                        const fileType = getFileType(item?.url);
                                        const handleDownload = (
                                          url: string,
                                          fileName?: string
                                        ) => {
                                          const link =
                                            document.createElement("a");
                                          link.href = url;
                                          link.setAttribute(
                                            "download",
                                            fileName || "file"
                                          ); // Set file name
                                          link.setAttribute("target", "_blank"); // Open in a new tab if the browser doesn't support download

                                          // Forcing the download for pdf and stp files
                                          if (
                                            url.endsWith(".pdf") ||
                                            url.endsWith(".stp")
                                          ) {
                                            // Fetch the file as a blob and trigger the download manually
                                            fetch(url)
                                              .then((response) =>
                                                response.blob()
                                              )
                                              .then((blob) => {
                                                const blobUrl =
                                                  window.URL.createObjectURL(
                                                    blob
                                                  );
                                                link.href = blobUrl;
                                                link.click();
                                                window.URL.revokeObjectURL(
                                                  blobUrl
                                                ); // Clean up after download
                                              })
                                              .catch((err) =>
                                                console.error(
                                                  "Error downloading file:",
                                                  err
                                                )
                                              );
                                          } else {
                                            document.body.appendChild(link);
                                            link.click();
                                            link.remove();
                                          }
                                        };
                                        return (
                                          <Box
                                            key={index}
                                            sx={{
                                              position: "relative",
                                              display: "flex",
                                              border: "0.1px solid #f2f2f2",
                                              borderRadius: "0.5rem",
                                              padding: "0.5rem",
                                              alignItems: "center",
                                              gap: "0.5rem",
                                              width: "100%",
                                              marginBottom: "0.5rem",
                                              backgroundColor: "#fff",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                          
                                              handleDownload(
                                                `${CDN_URL}${item?.url}`,
                                                item?.name
                                              );
                                            }}
                                          >
                                            <img
                                              src={
                                                item?.file_type === "Image"
                                                  ? imgIcon
                                                  : fileType === "pdf"
                                                  ? pdfIcon
                                                  : docIcon
                                              }
                                              alt={item?.name}
                                              width={25}
                                              height={25}
                                            />

                                            <Typography
                                              sx={{
                                                flex: 1,
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                maxWidth: "calc(100% - 35px)", // Adjust max width according to icon size
                                              }}
                                            >
                                              {item?.name}
                                            </Typography>
                                          </Box>
                                        );
                                      })}
                                  </Box>
                                )}

                            <Typography
                              variant="caption"
                              sx={{ marginTop: "5px", color: "gray" }} // Timestamp
                            >
                              {message?.date_added &&
                                moment(message?.date_added).format(
                                  "DD MMM YYYY, hh:mm A"
                                )}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        // For the logged-in user's messages
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "20px",
                            padding: "10px",
                          }}
                        >
                          <Box sx={{ maxWidth: "80%" }}>
                            {/* <Box
                              sx={{
                                padding: "10px",
                                backgroundColor: "#d3cde6",
                                borderRadius: "10px",

                                boxShadow: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  textAlign: "right",
                                }}
                              >
                                {message?.content}
                              </Typography>
                            </Box> */}



{(message?.content !== "" ||
                                  message?.attachment?.filter(
                                    (item: any) => item?.file_type === "Image"
                                  )?.length !== 0) && (
                                  <Box
                                    sx={{
                                      padding: "10px",
                                      backgroundColor: "#d3cde6",
                                      borderRadius: "10px",
                                      maxWidth: "100%",
                                      boxShadow: 1,
                                    }}
                                  >
                                    <Typography sx={{}}>
                                      {message?.content}
                                    </Typography>

                                    {message?.attachment?.length > 0 && (
                                      <ImageGallery message={message} />
                                    )}
                                  </Box>
                                )}

                                {message?.attachment?.length > 0 && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "0.5rem",
                                      marginTop: "10px",
                                      flexWrap: "wrap",
                                      width: "100%",
                                    }}
                                  >
                                    {message?.attachment
                                      ?.filter(
                                        (item: any) =>
                                          item?.file_type !== "Image"
                                      )
                                      ?.map((item: any) => {
                                        const fileType = getFileType(item?.url);
                                        const handleDownload = (
                                          url: string,
                                          fileName?: string
                                        ) => {
                                          const link =
                                            document.createElement("a");
                                          link.href = url;
                                          link.setAttribute(
                                            "download",
                                            fileName || "file"
                                          ); // Set file name
                                          link.setAttribute("target", "_blank"); // Open in a new tab if the browser doesn't support download

                                          // Forcing the download for pdf and stp files
                                          if (
                                            url.endsWith(".pdf") ||
                                            url.endsWith(".stp")
                                          ) {
                                            // Fetch the file as a blob and trigger the download manually
                                            fetch(url)
                                              .then((response) =>
                                                response.blob()
                                              )
                                              .then((blob) => {
                                                const blobUrl =
                                                  window.URL.createObjectURL(
                                                    blob
                                                  );
                                                link.href = blobUrl;
                                                link.click();
                                                window.URL.revokeObjectURL(
                                                  blobUrl
                                                ); // Clean up after download
                                              })
                                              .catch((err) =>
                                                console.error(
                                                  "Error downloading file:",
                                                  err
                                                )
                                              );
                                          } else {
                                            document.body.appendChild(link);
                                            link.click();
                                            link.remove();
                                          }
                                        };
                                        return (
                                          <Box
                                            key={index}
                                            sx={{
                                              position: "relative",
                                              display: "flex",
                                              border: "0.1px solid #f2f2f2",
                                              borderRadius: "0.5rem",
                                              padding: "0.5rem",
                                              alignItems: "center",
                                              gap: "0.5rem",
                                              width: "100%",
                                              marginBottom: "0.5rem",
                                              backgroundColor: "#d3cde6",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                             
                                              handleDownload(
                                                `${CDN_URL}${item?.url}`,
                                                item?.name
                                              );
                                            }}
                                          >
                                            <img
                                              src={
                                                item?.file_type === "Image"
                                                  ? imgIcon
                                                  : fileType === "pdf"
                                                  ? pdfIcon
                                                  : docIcon
                                              }
                                              alt={item?.name}
                                              width={25}
                                              height={25}
                                            />

                                            <Typography
                                              sx={{
                                                flex: 1,
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                maxWidth: "calc(100% - 35px)", // Adjust max width according to icon size
                                              }}
                                            >
                                              {item?.name}
                                            </Typography>
                                          </Box>
                                        );
                                      })}
                                  </Box>
                                )}

                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Typography
                                variant="caption"
                                sx={{
                                  marginTop: "5px",
                                  color: "gray",
                                  textAlign: "right",
                                }} // Timestamp
                              >
                                {message?.date_added &&
                                  moment(message?.date_added).format(
                                    "DD MMM YYYY, hh:mm A"
                                  )}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </>
                  );
                })}
            </Box>
          </Box>
        )}

        {/* Input box */}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            borderTop: "1px solid #e0e0e0",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {/* Image Previews above the input field */}
          {attchmentLoader ? (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1%",
                    marginBottom: "10px",
                    overflowX: "auto",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      width: "49%",
                      height: "2rem",
                      marginBottom: "0.5rem",
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      width: "49%",
                      height: "2rem",
                      marginBottom: "0.5rem",
                    }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",

                    gap: "1%",
                    marginBottom: "10px",
                    overflowX: "auto",
                    width: "100%",
                    flexWrap: "wrap",
                    maxHeight: "10rem",
                    overflowY: "auto",
                  }}
                >
                  {formik.values.attachments?.length > 0 &&
                    formik.values.attachments?.map((src, index) => {
                      const fileType = getFileType(src);
                      const fileName = getFileName(src);

                      return (
                        <Box
                          key={index}
                          sx={{
                            position: "relative",
                            display: "flex",
                            border: "0.1px solid #b7c4ba",
                            borderRadius: "0.5rem",
                            padding: "0.5rem",
                            alignItems: "center",
                            gap: "0.5rem",
                            width: "49%",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <img
                            src={
                              fileType === "image"
                                ? imgIcon
                                : fileType === "pdf"
                                ? pdfIcon
                                : docIcon
                            }
                            alt={fileType}
                            width={25}
                            height={25}
                          />

                          <Typography
                            sx={{
                              flex: 1,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "calc(100% - 35px)", // Adjust max width according to icon size
                            }}
                          >
                            {fileName}
                          </Typography>

                          <CloseIcon
                            sx={{ color: "#b7c4ba", cursor: "pointer" }}
                            onClick={() => {
                              const newAttachments =
                                formik.values.attachments.filter(
                                  (_, i) => i !== index
                                );
                              formik.setFieldValue(
                                "attachments",
                                newAttachments
                              );
                            }}
                          />
                        </Box>
                      );
                    })}
                </Box>
              )}

          {/* Input and buttons */}
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Ask anything..."
              sx={{ marginRight: "10px", borderRadius: "10px" }}
              value={messageContent}
              onChange={(e) => setMessageContent(e.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && messageContent.trim()) {
                  sendMessage();
                }
              }}
            />
            

            <input
              style={{ display: "none" }}
              id="file-upload"
              type="file"
              multiple
              accept=".pdf,.jpg,.jpeg,.png"
              onChange={handleFilesUpload}
            />
            <label htmlFor="file-upload">
              <IconButton
                color="primary"
                component="span"
                sx={{ marginRight: "10px" }}
              >
                <AttachFileIcon />
              </IconButton>
            </label>

            <Button
              onClick={sendMessage}
              variant="contained"
              color="primary"
              disabled={
                !messageContent.trim() &&
                formik.values.attachments?.length === 0
              }
              aria-label="Send message"
            >
              Send
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

export default SingleChat;
