import React, { useEffect, useState } from "react";
import { API } from "../api-services";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import Swal from "sweetalert2";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useBaseParamsModal } from "../CustomHook/useBaseParamsModal";
import UserAddressModal from "./UserAddressModal";
import ProductDetails from "./ProductDetails";
import CourierDetailsForm from "./CourierDetailsForm";
import CDN_URL from "../utlis/CDN_URL";
import MessageIcon from "@mui/icons-material/Message";
import SingleChat from "../chat/SingleChat";

function OrderedParts() {
  const [orders, setOrders] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedRequest, setSelectedRequest] = React.useState<any>(null);
  const [selectedOption, setSelectedOption] = React.useState("");
  const deliveryAddressModal = useBaseParamsModal();
  const courierDetailsModal = useBaseParamsModal();
  const editCourierDetailsModal: any = useBaseParamsModal();
  const productsModal: any = useBaseParamsModal();
  const orderRows =
    orders &&
    orders?.map((item: any, index: number) => {
      return {
        id: item?.id,
        idd: index + 1,
        orderId: item?.order_id,
        room_id:item?.room_id,
        customerName: `${item?.user_details?.name}`,
        orderDate: item?.request_stage_details?.[0]?.date,
        deliveryAddress: item?.billing_address,
        loader: false,
        orderStatus:
          // "order_shipped",
          item?.request_stage_details?.length == 1
            ? "order_placed"
            : item?.request_stage_details?.length == 2
            ? "order_shipped"
            : item?.request_stage_details?.length == 3
            ? "order_delivered"
            : item?.request_stage_details?.length == 4
            ? "order_delivered"
            : "",

        status: "",
        shipping_details: item?.shipping_details,
        deliveryPartner: item?.deliveryPartner,
        trackingNumber: item?.trackingNumber,
        parts: item?.part_details,
        paymentStatus: "Paid",
      };
    });

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

 
  

  // useEffect(() => {
  //   if (orderRows) {
  //     setRows(orderRows);
  //   }
  // }, [orderRows]);

  // console.log(orders);
  // console.log(orderRows);

  // Initial Column Definitions
  const initialColumns: GridColDef[] = [
    { field: "idd", headerName: "Sl No.", width: 80 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,

      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
            <Tooltip title="Chat with requested user">
              <MessageIcon
                sx={{
                  color: "#00887a",
                  fontSize: "1.5rem",
                  cursor: "pointer",
                  display: "grid",
                  placeItems: "center",
                }}
                onClick={() => {
                  handleClickk();
                  setSelectedRequest(cellValues.row);
                }}
              />
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "orderId",
      headerName: "Order ID",
      width: 200,
      renderCell: (params) => {
        return <>{params.value || "--"}</>;
      },
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 200,
      renderCell: (params) => {
        return <>{params.value || "--"}</>;
      },
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      width: 180,
      type: "date",

      renderCell: (params) => {
        return <>{moment(params?.value).format("Do MMM h:mm A") || "--"}</>;
      },
    },
    {
      field: "deliveryAddress",
      headerName: "Delivery Address",
      width: 200,

      renderCell: (params) => {
        return (
          <Box
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => deliveryAddressModal.open(params?.value)}
          >
            View Address
          </Box>
        );
      },
    },

    {
      field: "orderDetails",
      headerName: "Order Details",
      width: 180,

      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              productsModal.open({
                parts: params.row?.parts,
                status: params.row?.status,
                address: params.row?.billing_address,
              });
            }}
          >
            View Details
          </Box>
        );
      },
    },

    { field: "paymentStatus", headerName: "Payment Status", width: 150 },
    {
      field: "orderStatus",
      headerName: "Order Status",
      width: 350,
      renderCell: (params) => {
        const { row } = params;

        const handleAcceptOrder = () => {
          console.log(`Order ${row.orderId} accepted`);
        };

        const handleRejectOrder = () => {
          console.log(`Order ${row.orderId} rejected`);
        };

        const handleMarkAsDelivered = () => {
          console.log(`Order ${row.orderId} marked as delivered`);
        };
        if (
          row.orderStatus === "order_placed" ||
          row.orderStatus === "order_accepted"
        ) {
          return (
            <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
              <Typography sx={{ fontSize: "small" }}>Order Received</Typography>
              <Button
                variant="outlined"
                size="small"
                // sx={{fontSize:"small",}}
                sx={{
                  width: "16.5rem",
                  fontSize: "0.85rem", // or adjust it to whatever size you need
                  textTransform: "capitalize", // This will make only the first letter uppercase
                }}
                onClick={() => courierDetailsModal.open(params?.row?.id)}
              >
                Add Shipment Details
              </Button>
            </Box>
          );
        }

        if (row.orderStatus === "order_shipped") {
          return (
            // <TextField
            //   select
            //   variant="outlined"
            //   size="small"
            //   defaultValue="" // Ensure the initial value is empty
            //   onChange={(e) => {
            //     if (e.target.value === "delivered") {
            //       handleMarkAsDelivered();
            //     }
            //   }}
            //   sx={{ width: "150px" }}
            // >
            //   {/* Visible placeholder item */}
            //   <MenuItem value="" disabled>
            //     Order Shipped
            //   </MenuItem>

            //   {/* Actual selectable options */}
            //   <MenuItem value="delivered">Delivered</MenuItem>
            // </TextField>
            <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
              <Typography sx={{ fontSize: "small" }}>Order Shipped</Typography>

              <FormControl fullWidth sx={{ width: "17rem" }}>
                <InputLabel>Update Status</InputLabel>
                <Select
                  value={selectedOption}
                  onChange={(e) => {
                    handleStatusChange(
                      params.row.id,
                      e.target.value,
                      params.row?.shipping_details
                    );
                  }}
                  displayEmpty
                  label="Update Status"
                  sx={{
                    fontSize: "0.85rem",
                    textTransform: "capitalize",
                    paddingTop: "1px",
                  }}
                >
                  <MenuItem value="Edit Shipping Address">
                    Edit Shipping Address
                  </MenuItem>
                  <MenuItem value="Change Status to Delivered">
                    Change Status to Delivered
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          );
        }

        if (row.orderStatus === "order_delivered") {
          return <Box>Delivered</Box>;
        }

        return "--";
      },
    },
    {
      field: "deliveryPartner",
      headerName: "Delivery Partner",
      width: 180,

      renderCell: (params) => {
        return <>{params.value || "--"}</>;
      },
    },
  ];

  const getAllParts = () => {
    setLoader(true)
    API.get(`cart/internal_product_order/`, { page_no: page }, 0)
      .then((res: any) => {
        console.log(res.data);
        
        setOrders(res.data);
      })
      .catch((err: any) => {
        const { data } = err.response;

        if (err && err.response && data) {
          Swal.fire({
            icon: "error",
            html: `<div>
            <br />
            <p style="color:"red">${data[0]}</p>   
             </div>`,
          });
        }
      }).finally(()=> {
        setLoader(false);
      });
  };

  const [rows, setRows] = useState(orderRows);
  const [columns, setColumns] = useState(initialColumns);
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedColumns, setSelectedColumns] = useState(
    initialColumns.map((col) => ({ ...col, visible: true }))
  );

  const [totalPagecount, settotalPagecount] = React.useState<any>();
  const [page, setPage] = React.useState(1);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  React.useEffect(() => {
    API.get("cart/internal_product_order/", { total_page: true })
      .then((res: any) => {
        settotalPagecount(res.data?.total_page);
      })
      .catch((err: any) => {
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
					<br />
					<p style="color:"red">${data.detail}</p>   
					</div>`,
        });
      });
  }, []);

  // // Handle Search
  // const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const query = event.target.value?.toLowerCase();
  //   setSearchQuery(query);
  //   setRows(
  //     initialRows.filter(
  //       (row) =>
  //         row.customerName.toLowerCase().includes(query) ||
  //         row.orderId.toLowerCase().includes(query) ||
  //         row.paymentStatus.toLowerCase().includes(query)
  //     )
  //   );
  // };

  // Handle Column Filter Menu
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleColumnToggle = (field: string) => {
    setSelectedColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.field === field ? { ...col, visible: !col.visible } : col
      )
    );
  };

  // Update Visible Columns
  React.useEffect(() => {
    setColumns(selectedColumns.filter((col) => col.visible));
  }, [selectedColumns]);

  React.useEffect(() => {
    getAllParts();
  }, [page]);

  const handleStatusChange = (
    id: number,
    value: string,
    courierDetails?: any
  ) => {
    //console.log(id, value);
    if (value == "Change Status to Delivered") {
      Swal.fire({
        title: "Are you sure?",
        text: "This action will change the status to delivered",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result: any) => {
        if (result.isConfirmed == true) {
          API.put(`cart/internal_product_order/`, {
            id: id,
            delivery_status: "Completed",
          })
            .then((res) => {
              getAllParts();
            })

            .catch((err: any) => {
              Swal.fire({
                icon: "error",
                title: `${err.response?.data[0]}`,
              });
            })
            .finally(() => {});
        }
      });
    } else {
      editCourierDetailsModal.open({
        id: id,
        details: courierDetails,
      });
    }
  };

  const PaginationHandler = (type: any) => {
    if (type == "Next") {
      setPage((prev: any) => prev + 1);
    } else {
      setPage((prev: any) => prev - 1);
    }
  };

  const filteredRows = orderRows?.filter((row: any) => {
    const searchLower = searchTerm?.toLowerCase();
    // console.log(searchLower, row?.orderId);

    return (
      row?.orderId?.toString()?.includes(searchLower) ||
      row?.customerName?.toString()?.includes(searchLower) ||
      row?.deliveryAddress?.toString()?.includes(searchLower) ||
      row?.orderDate?.toLowerCase()?.includes(searchLower)
    );
  });
  // console.log(orders);
  
  // console.log(orderRows);
  //  console.log(filteredRows);

  // ------------drawer-----------------
  const [openChatDrawer, setOpenChatDrawer] = React.useState(false);

  const handleClickk = () => {
    setOpenChatDrawer(true);
  };

  const handleClosee = () => {
    setOpenChatDrawer(false);
  };

  // -----------------------------

  

  return (
    <Box sx={{ padding: 0 }}>
      {productsModal.isOpen && (
        <ProductDetails
          isOpen={productsModal.isOpen}
          onCloseModal={() => productsModal.close()}
          parts={productsModal.propsId?.parts}
          address={productsModal.propsId?.address}
          status={productsModal.propsId?.status}
        />
      )}

      {openChatDrawer && (
        <SingleChat
          open={openChatDrawer}
          handleClose={() => {
            getAllParts();
            setSelectedRequest(null);
            handleClosee();
          }}
          request={selectedRequest}
          page_name="product_cart"
          heading={`Support desk for`}
        />
      )}

      {deliveryAddressModal.isOpen && (
        <UserAddressModal
          open={deliveryAddressModal.isOpen}
          onCloseModal={() => deliveryAddressModal.close()}
          address={deliveryAddressModal.propsId}
        />
      )}
      {courierDetailsModal.isOpen && (
        <CourierDetailsForm
          getAllParts={getAllParts}
          id={courierDetailsModal.propsId}
          open={courierDetailsModal.isOpen}
          onCloseModal={() => courierDetailsModal.close()}
          edit={false}
        />
      )}
      {editCourierDetailsModal.isOpen && (
        <CourierDetailsForm
          getAllParts={getAllParts}
          id={editCourierDetailsModal.propsId?.id}
          details={editCourierDetailsModal.propsId?.details}
          open={editCourierDetailsModal.isOpen}
          onCloseModal={() => editCourierDetailsModal.close()}
          edit={true}
        />
      )}
      {/* Toolbar */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 1,
        }}
      >
        <Box></Box>
        {/* <Box display="flex" justifyContent="center" borderRadius="14px">
            <TextField
              variant="outlined"
              placeholder="search orders"
              value={searchTerm}
              onChange={handleChangeSearch}
              fullWidth
              size="small"
              sx={{
                borderRadius: "12px", // Adjust the radius as needed
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                },
              }}
            />
          </Box> */}

        <Box
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            position: "relative",
            zIndex: 1,
          }}
        >
          <IconButton
            sx={{ cursor: page > 1 ? "pointer" : "not-allowed" }}
            disabled={page > 1 ? false : true}
            title="Previous Page"
            color="inherit"
            onClick={() => PaginationHandler("Previous")}
          >
            <ArrowCircleLeftIcon
              sx={{ cursor: page > 1 ? "pointer" : "not-allowed" }}
            />
          </IconButton>
          {page}
          <IconButton
            sx={{ cursor: page < totalPagecount ? "pointer" : "not-allowed" }}
            disabled={page < totalPagecount ? false : true}
            title="Next Page"
            color="inherit"
            onClick={() => PaginationHandler("Next")}
          >
            <ArrowCircleRightIcon
              sx={{ cursor: page < totalPagecount ? "pointer" : "not-allowed" }}
            />
          </IconButton>
        </Box>
      </Box>

      {/* DataGrid */}
      <Box
        sx={{
          height: { lg: "45vh", xl: "58vh" },
          width: "100%",
          "& .super-app-theme--order_placed": { bgcolor: "#fff" }, // Light Blue
          "& .super-app-theme--order_accepted": { bgcolor: "#add8e6" }, // Light Green
          "& .super-app-theme--order_shipped": { bgcolor: "#fffacd" }, // Light Yellow
          "& .super-app-theme--order_delivered": { bgcolor: "#90ee90" }, // Light Gray
          "& ::-webkit-scrollbar": { width: "0.3rem", height: "0.3rem" },
          "& ::-webkit-scrollbar-thumb": {
            backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
            borderRadius: "10px",
          },
          "&.MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { color: "text.primary" },
          "&.MuiDataGrid-root .MuiDataGrid-cell": {
            borderBottomColor: "primary.light",
          },
        }}
      >
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          getRowClassName={(params) =>
            `super-app-theme--${params.row?.orderStatus?.toLowerCase()}`
          }
          disableSelectionOnClick
          hideFooter
        />
      </Box>
    </Box>
  );
}

export default OrderedParts;
