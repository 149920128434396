import {
    Box,
    Button,
    Popover,
    Typography,
    IconButton,
    Skeleton,
    CircularProgress,
    TableCell,
    Table,
    TableRow,
    Tooltip,
    Select,
    MenuItem,
    InputLabel,
  } from "@mui/material";
  import MessageIcon from "@mui/icons-material/Message";
  import PublishIcon from '@mui/icons-material/Publish';
  import { DataGrid, GridColDef } from "@mui/x-data-grid";
  import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
  import { useBaseParamsModal } from "../CustomHook/useBaseParamsModal";
  import VisibilityIcon from "@mui/icons-material/Visibility";
  import React, { useEffect, useState } from "react";
  import { API } from "../api-services";
  import ScenarioRequestStatusModal from "./ScenarioRequestStatusModal";
  import { useHistory } from "react-router-dom";
  import Swal from "sweetalert2";
  import InfoIcon from "@mui/icons-material/Info";
  import DownloadIcon from "@mui/icons-material/Download";
  import LoadingButton from "@mui/lab/LoadingButton";
  import ScenarioAssignModal from "./ScenarioAssignModal";
  import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
  import LockIcon from "@mui/icons-material/Lock";
  import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
  import DescriptionIcon from '@mui/icons-material/Description';
  import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
  import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
  import SearchIcon from "@mui/icons-material/Search";
  import InputBase from "@mui/material/InputBase";
  import ClearIcon from "@mui/icons-material/Clear";
  import LaunchIcon from "@mui/icons-material/Launch";
  import Radio from "@mui/material/Radio";
  import RadioGroup from "@mui/material/RadioGroup";
  import FormControlLabel from "@mui/material/FormControlLabel";
  import FormControl from "@mui/material/FormControl";
  import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CDN_URL from "../utlis/CDN_URL";
import { get } from "lodash";
import PublishPartModal from "./PublishPartModal";
import SingleChat from "../chat/SingleChat";
  
  export interface IRequestScenarioTableProps {}
  
function UnlistedPartsTable(props: IRequestScenarioTableProps) {
  const [selectedRequest, setSelectedRequest] = React.useState<any>(null);
     const [unlistedPartsList, setUnlistedPartsList] = React.useState([]);
     const [fullfilledParts, setFullfilledParts] = React.useState([]);
     const pubmishPartModal = useBaseParamsModal();
    const assignScenario = useBaseParamsModal();
    const history = useHistory();
    const [scenarioData, setScenarioData] = React.useState<any>([]);
    const [counter, setCouner] = React.useState<any>(0);
    const [loader, setLoader] = React.useState<boolean>(false);
    const [currentStatus, setCurrentStatus] = React.useState<any>();
    const [publishloader, setPublishLoader] = React.useState<boolean>(false);
    const [currentRowId, setcurrentRowId] = React.useState<any>();
    // const [currentType, setCurrentType] = React.useState<any>();
    const senarioStausModal = useBaseParamsModal();
    // const [invoiceData, setinvoiceData] = React.useState<any>({});
    // Action Info Popover
    const [rowData, setRowData] = React.useState<any>({});
    const contentRef = React.useRef<any>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null
    );
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const handleClick = (cellValues: any, event: any) => {
      setRowData(cellValues);
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
      setRowData({});
    };
    // end of Action Info Popover
  
    // Pagination & search
    const [totalPagecount, settotalPagecount] = React.useState<any>();
    const [page, setPage] = React.useState(1);
    const [selectedPart, setSelectedPart] = useState<string>();

    
    const getAllUnlistedPartReq = () => {
        // API call to get all the Claim Business Request
        API.get(`cart/get_all_unlisted_part_for_internal/`, {}, 0)
          .then((res: any) => {
            setUnlistedPartsList(res.data);
          })
          .catch((err: any) => {
            const {data} = err.response;
    
            if(err && err.response && data){
            Swal.fire({
    
              icon: "error",
              html: `<div>
              <br />
              <p style="color:"red">${data[0]}</p>   
               </div>`,
            })
          }
          });
      };

      const getAllParts = () => {
        

        API.get(`cart/fulfill_unlisted_part/`, {}, 0)
        .then((res: any) => {
          setFullfilledParts(res.data);
        })
        .catch((err: any) => {
          const {data} = err.response;
  
          if(err && err.response && data){
          Swal.fire({
  
            icon: "error",
            html: `<div>
            <br />
            <p style="color:"red">${data[0]}</p>   
             </div>`,
          })
        }
        });
      }
  
    React.useEffect(() => {
      setLoader(true);
      API.get("cart/get_all_scenario_requests/", { get_page_count: true })
        .then((res: any) => {
          settotalPagecount(res.data?.page_count);
          setLoader(false);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
                      <br />
                      <p style="color:"red">${data.detail}</p>   
                      </div>`,
          });
          setLoader(false);
        });
    }, []);

    const truncateString = (str: any, maxLength: any) => {
        if (str?.length <= maxLength) {
          return str; // Return the full string if it's less than or equal to maxLength
        } else {
          return str?.slice(0, maxLength - 3) + "..."; // Truncate and add "..." for longer strings
        }
      };


  React.useEffect(() => {
    getAllUnlistedPartReq();

    // getAllParts()
  }, []);
  
    const [selectFilter, setSelectFilter] = React.useState("All");
    const filterZBCRequest = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectFilter(event.target.value);
    };
  
    const getScenarioData = () => {
      if (selectFilter == "All") {
        setLoader(true);
        API.get("cart/get_all_scenario_requests/", { page: page })
          .then((res: any) => {
            let dataList = res?.data?.map((item: any, index: any) => {
              return { ...item, ids: (page - 1) * res.data?.length + index + 1 };
            });
            setScenarioData(dataList);
            setLoader(false);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                              <br />
                              <p style="color:"red">${data.detail}</p>   
                              </div>`,
            });
            setLoader(false);
          });
      } else {
        setLoader(true);
        API.get("cart/get_all_scenario_requests/", {
          page: page,
          filter: selectFilter,
        })
          .then((res: any) => {
            let dataList = res?.data?.map((item: any, index: any) => {
              return { ...item, ids: (page - 1) * res.data?.length + index + 1 };
            });
            setScenarioData(dataList);
            setLoader(false);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                                  <br />
                                  <p style="color:"red">${data.detail}</p>   
                                  </div>`,
            });
            setLoader(false);
          });
      }
    };
  
    React.useEffect(() => {
      getScenarioData();
    }, [page, selectFilter]);
  
    const PaginationHandler = (type: any) => {
      if (type == "Next") {
        setPage((prev: any) => prev + 1);
      } else {
        setPage((prev: any) => prev - 1);
      }
    };
  
    const publishClicked = (row: any) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to lock and publish this scenario to xcPROC, which will make it visible to the client?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "primary.main",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setcurrentRowId(row.scenario_id);
          setPublishLoader(true);
          API.put(
            `/cost/scenario/${row.scenario_id}/`,
            {
              lock: true,
            },
            {},
            0
          )
            .then((res: any) => {
              getScenarioData();
              setPublishLoader(false);
              Swal.fire({
                title: "Success",
                text: "Scenario has been locked and Published to xcPROC successfully",
                icon: "success",
                confirmButtonColor: "primary.main",
              });
            })
            .catch((err: any) => {
              const { data } = err.response;
              setPublishLoader(false);
              Swal.fire({
                title: "Error",
                text: data[0],
                icon: "error",
                confirmButtonColor: "primary.main",
              });
            });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    };
    const [reopen, setReopen] = React.useState(false);
  
    const [searchValue, setSearchValue] = useState<any>("");
    useEffect(() => {
      setSearchValue(searchValue == "0" ? "" : searchValue);
    }, [searchValue]);
  
    const searchAPICall = (event: any) => {
      if (event.key === "Enter") {
        handleSearchIcon();
      }
    };
    const handleOnChange = (event: any) => {
      setSearchValue(event.target.value);
    };
  
    const handleSearchIcon = () => {
      setLoader(true);
      API.get("cart/get_all_scenario_requests/", { search_key: searchValue })
        .then((res) => {
          setScenarioData(res.data);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
                  <br />
                  <p style="color:"red">${data[0]}</p>   
                  </div>`,
          });
        });
    };
    const handleClearSearch = () => {
      setSearchValue("");
      getScenarioData();
    };
  
    const handleOpenSceanrio = (cellValues: any) => {
      const url = `/#/costingscenario/${cellValues.row?.vehicle_category_id}/${cellValues?.row?.vehicle_category}/0/${cellValues.row.vault}/1/${cellValues.row?.vehicle_category_abbreviation}/0/${cellValues.row?.ID}/1`;
      window.open(url);
    };
  
    React.useEffect(() => {
      getScenarioData();
    }, [counter]);
  
    const onInvoiceDownloadClick = async (invoice: any) => {
      try {
        // Fetch HTML content from the API endpoint
        const response = await API.get(
          `payment/get_invoice_from_payment_id/${invoice}/`
        );
        const data = await response.data;
        window.open(data?.invoice);
      } catch (error: any) {
        console.error("Error:", error); // Log the entire error object
        if (error?.response) {
          // The error object contains a response property
          const { data } = error?.response;
          Swal.fire({
            icon: "error",
            title: "Error!",
            html: `<div>
                      <p style="color:"red">${data[0]}</p>
                      </div>`,
          });
          console.error("Server Error:", error?.response);
        } else if (error?.message) {
          // The error object contains a message property
          console.error("Error Message:", error?.message);
          // Handle the error message
        } else {
          // The error object does not contain response or message properties
          console.error("Unknown Error:", error);
          // Handle the unknown error
        }
      }
    };



    const handleRequestApprove = (id:any) => {
 
        
        API.put(
            `cart/get_all_unlisted_part_for_internal/`,
            {
                id: id,
                fulfillment: true,
            },
           
          )
            .then((res: any) => {
                getAllUnlistedPartReq();
              setPublishLoader(false);
              Swal.fire({
                title: "Success",
                text: "Unlisted Part Request has been accepted successfully",
                icon: "success",
                confirmButtonColor: "primary.main",
              });
            })
            .catch((err: any) => {
              const { data } = err.response;
              setPublishLoader(false);
              Swal.fire({
                title: "Error",
                text: data[0],
                icon: "error",

              });
            });
    }

 


    const unlistedColums: GridColDef[] = [
        {
            field: "idd",
            headerName: "ID",
            width: 150,
            editable: false,
          },
        {
            field: "part_name",
            headerName: "Part Name",
            width: 350,
            editable: false,
          }, 
          {
            field: "part_quantity",
            headerName: "Part Quantity",
            width: 150,
            editable: false,
          }, 
         
          {
            field: "part_image",
            headerName: "Part Images",
            width: 150,
            editable: false,
            renderCell: (params) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}>
                  {params.row.part_image?.length > 0 ? (
                    params.row.part_image?.map((item: any, index: number) => (
                        <Box key={index}>
                       
                         
                          {/* Example of an icon */}
                          <VisibilityIcon onClick={() => window.open(`${CDN_URL}${item}`)} sx={{ color: "#00887A", cursor: "pointer" }} />
                        </Box>
                      ))
                  ) : "--"}
                </Box>
              );
            },
        },
        {
            field: "part_document",
            headerName: "Part Documents",
            width: 150,
            editable: false,
            renderCell: (params) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}>
                  {params.row.part_document?.length > 0 ? (
                    params.row.part_document?.map((item: any, index: number) => (
                        <Box key={index}>
                       
                         
                          {/* Example of an icon */}
                          <DescriptionIcon onClick={() => window.open(`${CDN_URL}${item}`)} sx={{ color: "#00887A", cursor: "pointer" }} />
                        </Box>
                      ))
                  ) : "--"}
                </Box>
              );
            },
        },
        {
            field: "part_description",
            headerName: "Part Description",
            width: 500,
            renderCell: (params) => 
            (
              <Tooltip title={params?.row?.description}>
              <Typography>
      {truncateString(params?.row?.description, 70)}
              </Typography>
              </Tooltip>
         
            )
          },
          {
            field: "user_details",
            headerName: "Requested By",
            width: 150,
            renderCell: (params) => 
            (
              
              <Typography>
      {params?.row?.user_details?.name}
              </Typography>
           
         
            )
          },
          {
            field: "fulfilled",
            headerName: "Status",
            width: 100,
            renderCell: (params) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}>
                  {params.row.fulfilled ? "Fulfilled" : "Pending"}
                </Box>
              );
            },
          }, 
          {
            field: "actions",
            headerName: "Actions",
            width: 100,
            renderCell: (params) => (
              <Box sx={{display:"flex", gap:"1.5rem", alignItems:"center", borderBottom:"0.1px solid grey"}} >
                 
<Tooltip title={params.row?.fulfilled ? "" : "Publish part requested by this user"}>
                  <IconButton sx={{cursor:"pointer", color:"#00887a"}} onClick={()=> {
                    pubmishPartModal.open(params.row);
                
                    
                  }} disabled={params.row?.fulfilled}>
                  <PublishIcon sx={{color:params.row?.fulfilled ? "#91cfc8" : "#00887a"}}  />
                  </IconButton>


                  </Tooltip>
                  <Tooltip title="Chat with requested user">
                  <MessageIcon
                  sx={{
                    color: "#00887a",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                    display: "grid",
                    placeItems: "center",
                  }}
                  onClick={()=> {
                    handleClickk();
                    setSelectedRequest(params.row);
                  }}
                />
               </Tooltip>
              </Box>
            ),
          }
         
          
    ]
  
   
  
 

      const unlistedrows =
      unlistedPartsList &&
      unlistedPartsList?.map((item: any, index: number) => {
        return {
          idd:index + 1,
          id: item?.id,
          ID: item.id,
          user_logo: item.user_logo,
          room_id: item.room_id,
          description: item.description,
          email: item.email,
          part_document: item.part_document,
          part_image: item.part_image,
          part_name: item.part_name,
          part_quantity: item.part_quantity,
          user_details: item.user_details,
          fulfilled: item.fulfilled,
        };
      });
  
    const senarioStausHandler = (allrowparams: any) => {
      // setCurrentStatus(curstatus);
      // setCurrentType(curtype);
      if (allrowparams && allrowparams.row) {
        const allparams = allrowparams.row;
  
        if (allparams) {
          let requestStage = allparams.request_stage;
          // let requestType = allparams.zbc_type;
          let reOpen = allparams.reOpen;
          // If allparams exists, set the values
          setCurrentStatus(requestStage);
          // setCurrentType(allparams.zbc_type);
          setReopen(allparams.reOpen);
          // Open the modal
          senarioStausModal.open(allrowparams.row);
        } else {
          // Handle the case when allparams is undefined or null
          console.log("No data found");
        }
      }
    };

         // ------------drawer-----------------
         const [openChatDrawer, setOpenChatDrawer] = React.useState(false);

         const handleClickk = () => {
           setOpenChatDrawer(true);
         };
       
         const handleClosee = () => {
           setOpenChatDrawer(false);
         };
       
         // -----------------------------

 
  
    return (
      <Box sx={{ height: { lg: "44vh", xl: "58vh" }, width: "100%" }}>
          {openChatDrawer && <SingleChat open={openChatDrawer}  handleClose={handleClosee} request={selectedRequest} page_name="unlisted_part" heading={`Unlisted part request for `} />}
        {assignScenario.isOpen && (
          <ScenarioAssignModal
            isOpen={assignScenario.isOpen}
            onCloseModal={assignScenario.close}
            item={assignScenario.propsId}
            getScenarioData={() => console.log("")}
            setCouner={setCouner}
          />
        )}
        {pubmishPartModal.isOpen && (
          <PublishPartModal
            isOpen={pubmishPartModal.isOpen}
            onCloseModal={pubmishPartModal.close}
            rowData={pubmishPartModal.propsId}
            getAllUnlistedPartReq={getAllUnlistedPartReq}
           
            setCouner={setCouner}
          />
        )}
  
        {senarioStausModal.isOpen && (
          <ScenarioRequestStatusModal
            isOpen={senarioStausModal.isOpen}
            onCloseModal={senarioStausModal.close}
            status={currentStatus}
            reOpen={reopen}
            rowData={senarioStausModal.propsId}
          />
        )}
     
        <Box
          sx={{
            height: { lg: "45vh", xl: "58vh" },
            width: "100%",
            "& .super-app-theme--not_started": {
              bgcolor: "secondary.main",
              // color: "#4646d3",
            },
            "& .super-app-theme--completed": {
              bgcolor: "#99dfbb",
              // color: "#4646d3",
            },
            "& .super-app-theme--in_progress": {
              bgcolor: "#ffffa3",
              // color: "#4646d3",
            },
          }}
        >
          <DataGrid
            headerHeight={38}
            rowHeight={38}
            rows={unlistedrows}
            columns={unlistedColums}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.color_code}`
            }
            sx={{
              "& ::-webkit-scrollbar": {
                width: "0.3rem",
                height: "0.3rem",
              },
              "& ::-webkit-scrollbar-thumb": {
                // background: $thumb-color;
                backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                borderRadius: "10px",
              },
              "& ::- webkit-scrollbar-thumb: hover ": {
                // width: "4px",
                backgroundColor: "#045DE9 !important",
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "#e1e1f3 !important",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                color: "text.primary",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                borderBottomColor: "primary.light",
              },
            }}
            loading={loader}
            hideFooter
            disableSelectionOnClick
          />
        </Box>
      </Box>
    );
  }
  
  export default UnlistedPartsTable;