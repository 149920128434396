import React, { useRef, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  Modal,
  FormControl,
} from "@mui/material";
import { Formik, Field, Form } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import * as Yup from "yup";
import { API } from "../api-services";
import { idID } from "@mui/material/locale";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import ImageUploadS3ProofDocs from "../DataBaseModule/Suppliers/ImageUploadS3ProofDocs";

interface FormValues {
  trackingNumber: string;
  trackingUrl: string;
  courierName: string;
  phoneNo: string;
  email: string;
  representativeName: string;
  file_path: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  // height: "60vh",
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 3,
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
};

const validationSchema = Yup.object({
  trackingNumber: Yup.string().required("Tracking number is required"),
  trackingUrl: Yup.string()
  .matches(
    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/,
    "Invalid URL format"
  )
    .required("Tracking URL is required"),
  courierName: Yup.string().required("Courier name is required"),
  //   phoneNo: Yup.string()
  //     .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
  //     .required('Phone number is required'),
  //   email: Yup.string().email('Invalid email format').required('Email address is required'),
  // representativeName: Yup.string().required('Representative name is required'),
});

const initialValues: FormValues = {
  trackingNumber: "",
  trackingUrl: "",
  courierName: "",
  phoneNo: "",
  email: "",
  representativeName: "",
  file_path: "",
};

function CourierDetailsForm({
  details,
  open,
  onCloseModal,
  edit,
  id,
  getAllParts,
}: any) {
  const MAX_TOTAL_FILE_SIZE_MB_LOGO = 5;
  const uploadRef = useRef<any>();
  const [loader, setLoader] = useState(false);
  const [logo, setlogo] = useState("");

  const [totalFileSize, setTotalFileSizeLogo] = useState(null);
  const [imagePreviewsLogo, setImagePreviewsLogo] = useState<any>(null);
  const [attachmentLoader, setAttachmentLoader] = useState(false);

  const handleSubmit = (values: FormValues) => {
    setLoader(true);
    console.log(values);
    // Handle form submission

    API.put(`cart/internal_product_order/`, {
      ...values,
      id: id,
      tracking_details: true,
    })
      .then((res) => {
        getAllParts();
        onCloseModal();
      })

      .catch((err: any) => {
        Swal.fire({
          icon: "error",
          title: `${err.response?.data[0]}`,
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getInitialValues = () => {
    return {
      trackingNumber: details?.trackingNumber || "",
      trackingUrl: details?.trackingUrl || "",
      courierName: details?.courierName || "",
      phoneNo: details?.phoneNo || "",
      email: details?.email || "",
      representativeName: details?.representativeName || "",
      file_path: details?.file_path || "",
    };
  };

  const browselogoFiles = () => {
    return uploadRef.current?.click?.();
  };
  const handleInputClicklogofile = (e: any) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            {edit ? "Edit" : "Add"} Shipment Details
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>

        <Formik
          initialValues={getInitialValues()}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, errors, touched, setFieldValue }) => (
            <Form>
              <Grid container spacing={3}>
                {/* Tracking Details Section */}
                <Grid item xs={12}>
                  <Typography variant="h6">Tracking Details</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="trackingNumber"
                    label="Tracking Number"
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    value={values.trackingNumber}
                    required={true}
                    sx={{"& .MuiInputLabel-asterisk": {
                     color: "red", // This targets the asterisk
                   }}}
                    onChange={handleChange}
                    helperText={
                      touched.trackingNumber ? errors.trackingNumber : ""
                    }
                    error={
                      touched.trackingNumber && Boolean(errors.trackingNumber)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="trackingUrl"
                    label="Tracking URL"
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    required={true}
                    sx={{"& .MuiInputLabel-asterisk": {
                     color: "red", // This targets the asterisk
                   }}}
                    value={values.trackingUrl}
                    onChange={handleChange}
                    helperText={touched.trackingUrl ? errors.trackingUrl : ""}
                    error={touched.trackingUrl && Boolean(errors.trackingUrl)}
                  />
                </Grid>

                {/* Courier Details Section */}
                <Grid item xs={12} sx={{ marginTop: 3 }}>
                  <Typography variant="h6">Courier Details</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="courierName"
                    label="Courier Partner Name"
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    value={values.courierName}
                    required={true}
                    sx={{"& .MuiInputLabel-asterisk": {
                     color: "red", // This targets the asterisk
                   }}}
                    onChange={handleChange}
                    helperText={touched.courierName ? errors.courierName : ""}
                    error={touched.courierName && Boolean(errors.courierName)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="phoneNo"
                    label="Phone Number"
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    value={values.phoneNo}
                    onChange={handleChange}
                    helperText={touched.phoneNo ? errors.phoneNo : ""}
                    error={touched.phoneNo && Boolean(errors.phoneNo)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="email"
                    label="Email Address"
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    helperText={touched.email ? errors.email : ""}
                    error={touched.email && Boolean(errors.email)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="representativeName"
                    label="Representative Name"
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    value={values.representativeName}
                    onChange={handleChange}
                    helperText={
                      touched.representativeName
                        ? errors.representativeName
                        : ""
                    }
                    error={
                      touched.representativeName &&
                      Boolean(errors.representativeName)
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{
                      ".MuiFormLabel-asterisk": { color: "red" },
                      ".MuiFormControl-root": {
                        width: "100%",
                      },
                    }}
                  >
                    <input
                      accept="image/*"
                      id="raised-button-file"
                      multiple
                      type="file"
                      style={{ display: "none" }}
                      ref={uploadRef}
                      onChange={(event: any) => {
                        const selectedFilesSize = event.target.files;

                        if (selectedFilesSize?.length <= 1) {
                          let totalSizeInBytes = 0;
                          // logoSingleFileupload(selectedFilesSize, setUploadedLogo, setLoader);
                          // Calculate the total size of all selected files
                          for (let i = 0; i < selectedFilesSize?.length; i++) {
                            totalSizeInBytes += selectedFilesSize[i].size;
                          }

                          const totalSizeInMB =
                            totalSizeInBytes / (1024 * 1024); // Convert bytes to megabytes
                          if (totalSizeInMB <= MAX_TOTAL_FILE_SIZE_MB_LOGO) {
                            //   setTotalFileSizeLogo(
                            //     totalSizeInMB.toFixed(2) + " MB"
                            //   );

                            const selectedFiles: any = Array.from(
                              event.currentTarget.files
                            );
                            setlogo(selectedFiles);
                            ImageUploadS3ProofDocs(
                              selectedFiles,
                              setFieldValue,
                              setAttachmentLoader,
                              "file_path"
                            );
                            if (
                              event.currentTarget.files &&
                              event.currentTarget.files.length > 0
                            ) {
                              const previews: any = Array.from(
                                event.currentTarget.files
                              ).map((item: any, index: any) => ({
                                url: URL.createObjectURL(item),
                                file: item,
                              }));

                              setImagePreviewsLogo(previews);
                            }
                          }
                        }
                      }}
                      onClick={handleInputClicklogofile}
                    />
                    <LoadingButton
                      loading={attachmentLoader}
                      variant="contained"
                      color="primary"
                      onClick={() => browselogoFiles()}
                      startIcon={<CloudUploadOutlinedIcon />}
                      sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                        px: 3,
                        py: 1.5,
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      Upload Supporting Document
                    </LoadingButton>

                    
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  {imagePreviewsLogo &&
                    imagePreviewsLogo?.map((item: any, index: any) => {
                      return (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: "0.5rem",
                              marginTop:"0.5rem"
                              
                            }}
                          >
                            <DescriptionIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => window.open(item?.url)}
                            />
                            <Typography
                              variant="body2"
                              sx={{ color: "primary.main", width: "100%" }}
                            >
                              {item.file.name}
                            </Typography>

                            <CloseIcon
                              titleAccess="Remove"
                              color="error"
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                setImagePreviewsLogo(null);
                                setFieldValue("file", "");
                              }}
                            />
                          </Box>
                        </>
                      );
                    })}
                </Grid>
              </Grid>

              

              <Box
                sx={{ marginTop: 3, display: "flex", justifyContent: "center" }}
              >
                <LoadingButton
                  loading={loader}
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ padding: "10px 20px" }}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default CourierDetailsForm;
