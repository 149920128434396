import {
  Box,
  Popover,
  Typography,
  IconButton,
  Table,
  TableRow,
  TableCell,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import * as React from "react";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import QuoteModal from "./QuoteModal";
import { useBaseParamsModal } from "../CustomHook/useBaseParamsModal";
import { API } from "../api-services";
import AddPartModal from "./AddPartModal";
import { useBaseModal } from "../ComponentUI/SearchPage/useBaseModal";
import ZBCRequestStatusModal from "./ZBCRequestStatusModal";
import { useHistory } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ZBCDocs from "./ZBCDocs";
import Swal from "sweetalert2";
import InfoIcon from "@mui/icons-material/Info";
import MessageIcon from "@mui/icons-material/Message";
import { LoadingButton } from "@mui/lab";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import LockIcon from "@mui/icons-material/Lock";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import DownloadIcon from "@mui/icons-material/Download";
import PublishAmountAndTime from "./PublishAmountAndTime";
import bomIcon from "../Assets/images/hierarchy.png";
import useWindowDimensions from "../ui-reusable-component/useWindowDimensions";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import ClearIcon from "@mui/icons-material/Clear";
import {
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import SingleChat from "../chat/SingleChat";

export interface IZBCTableProps {
  setParnetsCounter: any;
}

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const csvOptions: GridCsvExportOptions = {
  fileName: "ZBC Request Details In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer
    style={{
      height: "2.5rem",
      width: "25%",
      marginTop: "-2.5rem",
      zIndex: 1,
      position: "relative",
    }}
  >
    <span style={{ fontSize: "1rem", paddingRight: "1rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

export function ZBCTable(props: IZBCTableProps) {
  const { setParnetsCounter } = props;
  const history = useHistory();
  const [selectedRequest, setSelectedRequest] = React.useState<any>(null);
  const { searchKey } = useRouteParams<any>();
  const quoteopen = useBaseParamsModal();
  const allDocsModal = useBaseParamsModal();
  const addPartModal = useBaseParamsModal();
  const [ZBCRequest, setZBCRequest] = React.useState<any>([]);
  const [loader, setLoader] = React.useState<boolean>(false);
  const [currentStatus, setCurrentStatus] = React.useState<any>();
  const [currentType, setCurrentType] = React.useState<any>();
  const [publishloader, setPublishLoader] = React.useState<boolean>(false);
  const [currentRowId, setcurrentRowId] = React.useState<any>();
  const [reopen, setReopen] = React.useState(false);
  const zbcStausModal = useBaseModal();
  const [rowData, setRowData] = React.useState<any>({});

  const contentRef = React.useRef<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  // Pagination & search
  const [totalPagecount, settotalPagecount] = React.useState<any>();
  const [page, setPage] = React.useState(1);

  const InvoiceDownLoad = async (invoice: any) => {
    try {
      // Fetch HTML content from the API endpoint
      const response = await API.get(
        `payment/get_invoice_from_payment_id/${invoice}/`
      );
      const data = await response.data;
      // Create a new window and write the HTML content to it
      const iframe: any = document.createElement("iframe");

      iframe.style.display = "none";
      document.body.appendChild(iframe);
      // Write HTML content to the iframe
      iframe.contentDocument.write(data);
      // Trigger the print function on the iframe
      iframe.contentWindow.print();
    } catch (error) {
      console.error("Error fetching HTML content:", error);
    } finally {
      // setLoading(false);
    }
  };

  const handleClick = (
    data: any,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setRowData(data);

    setAnchorEl(event.currentTarget);
  };

  const { width, height } = useWindowDimensions();

  const handleClose = () => {
    setAnchorEl(null);
    setRowData({});
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    setLoader(true);
    API.get("cart/get_all_zbc_requests/", { get_page_count: true })
      .then((res: any) => {
        settotalPagecount(res.data?.page_count);
        setLoader(false);
      })
      .catch((err: any) => {
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
					<br />
					<p style="color:"red">${data.detail}</p>   
					</div>`,
        });
        setLoader(false);
      });
  }, []);

  const [selectFilter, setSelectFilter] = React.useState("All");
  const filterZBCRequest = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectFilter(event.target.value);
  };
  // console.log('selectFilter', selectFilter);
  const getZBCRequest = () => {
    if (selectFilter == "All") {
      setLoader(true);
      API.get("cart/get_all_zbc_requests/", { page: page })
        .then((res: any) => {
          let dataList = res?.data?.map((item: any, index: any) => {
            return { ...item, ids: (page - 1) * res.data?.length + index + 1 };
          });
          setZBCRequest(dataList);
          setLoader(false);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
		  <br />
		  <p style="color:"red">${data.detail}</p>   
		   </div>`,
          });
          setLoader(false);
        });
    } else {
      setLoader(true);
      API.get("cart/get_all_zbc_requests/", {
        page: page,
        filter: selectFilter,
      })
        .then((res: any) => {
          let dataList = res?.data?.map((item: any, index: any) => {
            return { ...item, ids: (page - 1) * res.data?.length + index + 1 };
          });
          setZBCRequest(dataList);
          setLoader(false);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
		  <br />
		  <p style="color:"red">${data.detail}</p>   
		   </div>`,
          });
          setLoader(false);
        });
    }
  };

  React.useEffect(() => {
    getZBCRequest();
  }, [page, selectFilter]);

  const PaginationHandler = (type: any) => {
    if (type == "Next") {
      setPage((prev: any) => prev + 1);
    } else {
      setPage((prev: any) => prev - 1);
    }
  };

  const [Counter, setCounter] = React.useState(1);
  const Publishmodal = useBaseParamsModal();
  const AmountHandlerandPublish = (data: any) => {
    let context_object = {
      id: data.vault,
      lock: data.lock,
      scenario_amount: data.scenario_amount,
      tat: data.tat,
    };
    Publishmodal.open(context_object);
  };

  const handleVaultInTrash = () => {
    Swal.fire({
      icon: "error",
      html: `<div>
			<b style="color:red">The Part is currently in the trash.</b>
			<br />
			<p style="margin-top:0.5rem;color:black;font-size:1rem;">Please take appropriate action to either restore or permanently delete it.</p>
		  </div>`,
    });
  };

  const onInvoiceDownloadClick = async (invoice: any) => {
    try {
      // Fetch HTML content from the API endpoint
      const response = await API.get(
        `payment/get_invoice_from_payment_id/${invoice}/`
      );
      const data = await response.data;
      window.open(data?.invoice);
    } catch (error: any) {
      console.error("Error:", error); // Log the entire error object
      if (error?.response) {
        // The error object contains a response property
        const { data } = error?.response;
        Swal.fire({
          icon: "error",
          title: "Error!",
          html: `<div>
					<p style="color:"red">${data[0]}</p>
					</div>`,
        });
        console.error("Server Error:", error?.response);
      } else if (error?.message) {
        // The error object contains a message property
        console.error("Error Message:", error?.message);
        // Handle the error message
      } else {
        // The error object does not contain response or message properties
        console.error("Unknown Error:", error);
        // Handle the unknown error
      }
    }
  };

  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'S.No.', width: 50 },
    {
      field: "actions",
      headerName: "Actions",
      width: width > 1440 ? 260 : 250,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
            <IconButton
              aria-describedby={id}
              onClick={(event) => handleClick(cellValues, event)}
              size="small"
            >
              <InfoIcon />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              elevation={1}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {rowData && Object.keys(rowData)?.length > 0 && (
                <Box
                  key={rowData.id}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      textAlign: "center",
                      width: "100%",
                      backgroundColor: "primary.light",
                      // color: "white",
                    }}
                  >
                    ZBC Request Info
                  </Typography>
                  <Table>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Phone Number
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {rowData.row.phone && rowData.row.phone.length > 0 ? (
                          <span>{rowData.row.phone}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Email ID
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {rowData.row.email && rowData.row.email.length > 0 ? (
                          <span>{rowData.row.email}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Company Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {rowData.row.company_name &&
                        rowData.row.company_name.length > 0 ? (
                          <span>{rowData.row.company_name}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Part
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {rowData.row.vault_name &&
                        rowData.row.abbreviation &&
                        rowData.row.vault_name.length > 0 &&
                        rowData.row.abbreviation.length > 0 ? (
                          <span>
                            {rowData.row.vault_name} ({rowData.row.abbreviation}
                            )
                          </span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Product Category
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {rowData.row.vehicle_category}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {rowData.row.description &&
                        rowData.row.description.length > 0 ? (
                          <span>{rowData.row.description}</span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Quoted Amount
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {rowData.row.currency &&
                        rowData.row.quoted_amount &&
                        rowData.row.quoted_amount > 0 ? (
                          <span>
                            {rowData.row.currency} {rowData.row.quoted_amount}
                          </span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Assignees
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {rowData.row.assignees &&
                        rowData.row.assignees.length > 0 ? (
                          rowData.row.assignees.map(
                            (assignee: { first_name: string; id: string }) => (
                              <span key={assignee.id}>
                                {assignee.first_name};{" "}
                              </span>
                            )
                          )
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Turn Around Time (TAT)
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {rowData.row.zbc_tat_days &&
                        rowData.row.zbc_tat_days > 0 ? (
                          <span>
                            {rowData.row.expected_outcome_date} (
                            {rowData.row.zbc_tat_days} Days)
                          </span>
                        ) : (
                          <span>--</span>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Requested Product Category
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {rowData.row.requested_vehicle_category &&
                        rowData.row.requested_vehicle_category.length > 0 ? (
                          <span>
                            {rowData.row.vehicle_category_obj} (
                            {rowData.row.requested_vehicle_category})
                          </span>
                        ) : (
                          <span>{rowData.row.vehicle_category_obj}</span>
                        )}
                      </TableCell>
                    </TableRow>
                  </Table>
                </Box>
              )}
            </Popover>
            <IconButton size="small">
              <RequestQuoteIcon
                titleAccess="Quote Money and TAT"
                sx={{
                  // marginRight: '1rem',
                  cursor: "pointer",
                  color: cellValues.row.quoted_amount ? "primary.main" : "red",
                }}
                onClick={() => quoteopen.open(cellValues.row)}
              />
            </IconButton>
            {cellValues.row.add_part ? (
              <IconButton size="small">
                <AddCircleOutlineIcon
                  titleAccess="Create Part"
                  onClick={() => addPartModal.open(cellValues.row)}
                  sx={{
                    cursor: "pointer",
                    color: "primary.main",
                  }}
                />
              </IconButton>
            ) : (
              <IconButton size="small" disabled>
                <AddCircleOutlineIcon
                  titleAccess="Part Created"
                  sx={{
                    cursor: "not-allowed",
                  }}
                />
              </IconButton>
            )}
            {cellValues.row.redirect_vehicle_category && (
              <IconButton size="small">
                <OpenInNewIcon
                  titleAccess="Create category"
                  sx={{
                    // marginRight: '1rem',
                    color: "primary.main",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/project_directory")}
                />
              </IconButton>
            )}
            {cellValues.row.vault && (
              <>
                {cellValues.row.vault_in_trash == false ? (
                  <IconButton
                    size="small"
                    title="GO to BOM"
                    onClick={() => {
                      //   console.log(cellValues.row);
                      sessionStorage.removeItem("BOMCreateNode");
                      sessionStorage.setItem(
                        "BOMCreateNode",
                        JSON.stringify(cellValues.row?.ancestors)
                      );
                      window.open(
                        `#/bomentry/${cellValues.row.ancestors[0]}/${
                          cellValues.row.vehicle_category
                        }/0/${
                          cellValues.row.vault || cellValues.row.ancestors[0]
                        }/${cellValues.row.abbreviation}/0/${
                          cellValues.row.is_leaf
                        }/false/true`
                      );
                    }}
                  >
                    <img
                      src={bomIcon}
                      style={{
                        width: "1.5rem",
                        height: "auto",
                        margin: "0 auto",
                      }}
                    />
                  </IconButton>
                ) : (
                  <Box>
                    <IconButton
                      size="small"
                      title="Error"
                      onClick={handleVaultInTrash}
                    >
                      <img
                        src={bomIcon}
                        style={{
                          width: "1.5rem",
                          height: "auto",
                          margin: "0 auto",
                        }}
                        alt="BOM Icon"
                      />
                    </IconButton>
                  </Box>
                )}
              </>
            )}
            {cellValues.row.allow_publish && (
              <>
                {cellValues.row.vault_in_trash == false ? (
                  <LoadingButton
                    loading={
                      cellValues.row.request_id == currentRowId
                        ? publishloader
                        : false
                    }
                    color="primary"
                    sx={{
                      backgroundColor: cellValues.row?.published
                        ? "#c5c5c5"
                        : cellValues.row?.request_stage == "completed"
                        ? "primary.main"
                        : "primary.light",
                      marginRight: "10px",
                    }}
                    size="small"
                    disabled={cellValues.row?.published}
                    title="Publish to xcPROC"
                    onClick={() =>
                      AmountHandlerandPublish({
                        ...cellValues?.row,
                        ...{ lock: true },
                      })
                    }
                  >
                    {cellValues.row.published ? (
                      "Published"
                    ) : (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <LockIcon /> <ArrowRightAltIcon />
                        <PublishedWithChangesIcon />
                      </Box>
                    )}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    loading={
                      cellValues.row.request_id == currentRowId
                        ? publishloader
                        : false
                    }
                    color="primary"
                    sx={{
                      backgroundColor: cellValues.row?.published
                        ? "#c5c5c5"
                        : "primary.light",
                      marginRight: "10px",
                    }}
                    size="small"
                    disabled={cellValues.row?.published}
                    title="Publish to xcPROC"
                    onClick={handleVaultInTrash}
                  >
                    {cellValues.row.published ? (
                      "Published"
                    ) : (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <LockIcon /> <ArrowRightAltIcon />
                        <PublishedWithChangesIcon />
                      </Box>
                    )}
                  </LoadingButton>
                )}
              </>
            )}
            <Tooltip title="Chat with requested user">
              <MessageIcon
                sx={{
                  color: "#00887a",
                  fontSize: "1.5rem",
                  cursor: "pointer",
                  display: "grid",
                  placeItems: "center",
                }}
                onClick={() => {
                  handleClickk();
                  setSelectedRequest(cellValues.row);
                }}
              />
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "invoice",
      headerName: "Invoice",
      width: 60,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.invoice ? (
              <>
                <IconButton
                  size="small"
                  sx={{ cursor: "pointer", color: "primary.main" }}
                  title="Download Invoice"
                  onClick={() => onInvoiceDownloadClick(cellValues.row.invoice)}
                >
                  <DownloadIcon />
                </IconButton>
              </>
            ) : (
              "--"
            )}
          </>
        );
      },
    },
    {
      field: "request_stage",
      headerName: "Status",
      width: 170,
      editable: false,
      renderCell(params) {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => zbcStausHandler(params)}
          >
            {params.row.request_stage}
          </Box>
        );
      },
    },
    {
      field: "request_id",
      headerName: "Request ID",
      width: 150,
      editable: false,
    },
    {
      field: "username",
      headerName: "Requested By",

      width: 150,
      editable: false,
    },
    {
      field: "zbc_type",
      headerName: "Request Details",
      width: 150,
      editable: false,
    },
    {
      field: "part_type",
      headerName: "Part Type",
      width: 90,
      editable: false,
    },
    {
      field: "part_name",
      headerName: "Requested Part Name",
      width: 160,
      editable: false,
    },
    {
      field: "combine_cost",
      headerName: "Amount Paid (excluding taxes)",
      width: 110,
      editable: false,
      align: "right",
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.paid_currency &&
            cellValues.row.paid_amount !== null ? (
              <>
                {cellValues.row.paid_currency} {cellValues.row.paid_amount}
              </>
            ) : (
              "--"
            )}
          </>
        );
      },
    },
    {
      field: "revision_month",
      headerName: "Revised On",
      width: 150,
      editable: false,
      renderCell: (cellValues: any) => {
        return (
          <>
            {Array.isArray(cellValues.row?.revision_month) &&
              cellValues.row?.revision_month?.join(", ")}
          </>
        );
      },
    },
    {
      field: "annual_volume",
      headerName: "Annual Volume",
      width: 150,
      editable: false,
    },
    {
      field: "mfg_location",
      headerName: "MFG Location",
      width: 150,
      editable: false,
    },
    {
      field: "docs",
      headerName: "View All Documents",
      width: 80,
      renderCell: (cellValues: any) => {
        return (
          <>
            {
              <IconButton
                size="small"
                sx={{ cursor: "pointer", color: "primary.main" }}
              >
                <VisibilityIcon
                  titleAccess="Publish new scenario costing"
                  sx={{ cursor: "pointer" }}
                  onClick={() => allDocsModal.open(cellValues.row)}
                />
              </IconButton>
            }
          </>
        );
      },
    },
  ];

  const rows: any =
    ZBCRequest &&
    ZBCRequest?.map((item: any, index: number) => {
      return {
        id: item?.id,
        ids: index + 1,
        user_logo: item.user_logo,
        ID: item.id,
        username: item.username,
        email: item.email,
        phone: item.phone,
        zbc_type: item.zbc_type,
        room_id: item?.room_id,
        category: item.category,
        annual_volume: item.annual_volume,
        mfg_location: item.mfg_location,
        revision_month: item.revision_month,
        request_stage: item.internal_request_stage,
        invoice: item.invoice,
        quoted_amount: item.quoted_amount,
        zbc_tat_days: item.zbc_tat_days,
        assignees: item?.assignees,
        part_name: item?.part_name,
        vault_name: item?.vault_name,
        add_part: item.add_part,
        allow_publish: item.allow_publish,
        redirect_vehicle_category: item.redirect_vehicle_category,
        vault: item.vault,
        ancestors: item.ancestors,
        abbreviation: item.abbreviation,
        vehicle_category: item.vehicle_category,
        reOpen: item.reopen,
        cad_doc: item.cad_doc,
        costing_guidelines: item.costing_guidelines,
        part_documents: item.part_documents,
        company_name: item.company_name,
        vehicle_category_obj: item.vehicle_category_obj,
        requested_vehicle_category: item?.requested_vehicle_category,
        published: item.published,
        color_code: item.color_code,
        request_id: item.request_id,
        description: item.description,
        currency: item.currency,
        auto_select: item.auto_select,
        accepted_rejected_by: item.accepted_rejected_by,
        expected_outcome_date: item.expected_outcome_date,
        part_type: item.part_type,
        is_leaf: item.is_leaf,
        paid_amount: item.paid_amount,
        paid_currency: item.paid_currency,
        vault_in_trash: item.vault_in_trash,
        scenario_amount: item.scenario_amount,
        tat: item.tat,
      };
    });

  const zbcStausHandler = (allrowparams: any) => {
    if (allrowparams && allrowparams.row) {
      const allparams = allrowparams.row;

      if (allparams) {
        let requestStage = allparams.request_stage;
        let requestType = allparams.zbc_type;
        let reOpen = allparams.reOpen;
        // If allparams exists, set the values
        setCurrentStatus(requestStage);
        setCurrentType(allparams.zbc_type);
        setReopen(allparams.reOpen);
        // Open the modal
        zbcStausModal.open();
      } else {
        // Handle the case when allparams is undefined or null
        console.log("allparams is not defined or null");
      }
    }
  };

  // Search
  const [searchValue, setSearchValue] = React.useState<any>("");
  React.useEffect(() => {
    setSearchValue(searchValue == "0" ? "" : searchValue);
  }, [searchValue]);
  React.useEffect(() => {
    if (searchKey != 0) {
      setSearchValue(searchKey);
      handleSearchIconRequest(searchKey);
    }
  }, [searchKey]);

  const searchAPICall = (event: any) => {
    if (event.key === "Enter") {
      handleSearchIcon();
    }
  };
  const handleOnChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleSearchIcon = () => {
    setLoader(true);
    API.get("cart/get_all_zbc_requests/", { search_key: searchValue })
      .then((res) => {
        setZBCRequest(res.data);
        history.push(`/requestPage/1/${0}/${searchValue}`);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
			<br />
			<p style="color:"red">${data[0]}</p>   
			</div>`,
        });
      });
  };
  const handleSearchIconRequest = (searchKeyString: any) => {
    setLoader(true);
    API.get("cart/get_all_zbc_requests/", { search_key: searchKeyString })
      .then((res) => {
        setZBCRequest(res.data);
        history.push(`/requestPage/1/${0}/${searchKeyString}`);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
			<br />
			<p style="color:"red">${data[0]}</p>   
			</div>`,
        });
      });
  };

  const handleClearSearch = () => {
    setSearchValue("");
    getZBCRequest();
  };

  // ------------drawer-----------------
  const [openChatDrawer, setOpenChatDrawer] = React.useState(false);

  const handleClickk = () => {
    setOpenChatDrawer(true);
  };

  const handleClosee = () => {
    setOpenChatDrawer(false);
  };

  // -----------------------------

  return (
    <Box sx={{ height: { lg: "44vh", xl: "63vh" }, width: "100%" }}>
      {openChatDrawer && (
        <SingleChat
          open={openChatDrawer}
          handleClose={() => {
            getZBCRequest();
            setSelectedRequest(null);
            handleClosee();
          }}
          request={selectedRequest}
          page_name="new_sc_request"
          heading={`New SC request for `}
        />
      )}
      {quoteopen.isOpen && (
        <QuoteModal
          isOpen={quoteopen.isOpen}
          onCloseModal={quoteopen.close}
          item={quoteopen.propsId}
          getZBCRequest={getZBCRequest}
        />
      )}

      <PublishAmountAndTime
        onCloseModal={Publishmodal.close}
        isOpen={Publishmodal.isOpen}
        Item={Publishmodal.propsId}
        setCounter={setCounter}
        getZBCRequest={getZBCRequest}
      />

      {allDocsModal.isOpen && (
        <ZBCDocs
          isOpen={allDocsModal.isOpen}
          onCloseModal={allDocsModal.close}
          item={allDocsModal.propsId}
        />
      )}
      {addPartModal.isOpen && (
        <AddPartModal
          isOpen={addPartModal.isOpen}
          onCloseModal={addPartModal.close}
          item={addPartModal.propsId}
          getZBCRequest={getZBCRequest}
        />
      )}
      {zbcStausModal.isOpen && (
        <ZBCRequestStatusModal
          isOpen={zbcStausModal.isOpen}
          onCloseModal={zbcStausModal.close}
          status={currentStatus}
          currentType={currentType}
          reOpen={reopen}
        />
      )}
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          columnGap: "1rem",
          padding: "0 1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#e5e5e5",
            borderRadius: 1,
            width: "18rem",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search first ZBC requests"
            inputProps={{ "aria-label": "Search first ZBC requests" }}
            onChange={handleOnChange}
            onKeyDown={searchAPICall}
            value={searchValue}
          />
          {searchValue ? (
            <IconButton
              title="Clear Search"
              size="small"
              sx={{
                cursor: "pointer",
                color: "primary.main",
                backgroundColor: "primary.light",
                padding: "0.2rem",
                marginRight: "0.5rem",
              }}
              aria-label="clear-search"
              onClick={handleClearSearch}
            >
              {" "}
              <ClearIcon sx={{ fontSize: "0.87rem" }} />
            </IconButton>
          ) : (
            <Box sx={{ width: "3rem" }}></Box>
          )}
          <IconButton
            type="button"
            onClick={handleSearchIcon}
            sx={{ cursor: "pointer", color: "primary.main", padding: "0.2rem" }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            position: "relative",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              position: "relative",
              zIndex: 1,
            }}
          >
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="All"
                name="radio-buttons-group"
                onChange={filterZBCRequest}
                value={selectFilter}
              >
                <FormControlLabel
                  defaultChecked
                  value="All"
                  control={<Radio />}
                  label="All"
                />
                <FormControlLabel
                  value="Completed"
                  control={<Radio />}
                  label="Completed"
                />
                <FormControlLabel
                  value="In Progress"
                  control={<Radio />}
                  label="In Progress"
                />
                <FormControlLabel
                  value="Not Started"
                  control={<Radio />}
                  label="Not Started"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <IconButton
            sx={{ cursor: page > 1 ? "pointer" : "not-allowed" }}
            disabled={page > 1 ? false : true}
            title="Previous Page"
            color="inherit"
            onClick={() => PaginationHandler("Previous")}
          >
            <ArrowCircleLeftIcon
              sx={{ cursor: page > 1 ? "pointer" : "not-allowed" }}
            />
          </IconButton>
          {page}
          <IconButton
            sx={{ cursor: page < totalPagecount ? "pointer" : "not-allowed" }}
            disabled={page < totalPagecount ? false : true}
            title="Next Page"
            color="inherit"
            onClick={() => PaginationHandler("Next")}
          >
            <ArrowCircleRightIcon
              sx={{ cursor: page < totalPagecount ? "pointer" : "not-allowed" }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          height: { lg: "45vh", xl: "58vh" },
          width: "100%",
          "& .super-app-theme--not_started": {
            bgcolor: "secondary.main",
          },
          "& .super-app-theme--completed": {
            bgcolor: "#99dfbb",
          },
          "& .super-app-theme--in_progress": {
            bgcolor: "#ffffa3",
          },
        }}
      >
        <DataGrid
          headerHeight={38}
          rowHeight={38}
          rows={rows}
          columns={columns}
          // components={{
          // 	Toolbar: () => {
          // 	  return CustomToolbar();
          // 	},
          // 	noRowsOverlay: CustomNoRowsOverlay,
          //   }}
          getRowClassName={(params) =>
            `super-app-theme--${params.row.color_code}`
          }
          sx={{
            "& ::-webkit-scrollbar": {
              width: "0.3rem",
              height: "0.3rem",
            },
            "& ::-webkit-scrollbar-thumb": {
              // background: $thumb-color;
              backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
              borderRadius: "10px",
            },
            "& ::- webkit-scrollbar-thumb: hover ": {
              // width: "4px",
              backgroundColor: "red !important",
            },
            "::-webkit-scrollbar-track": {
              backgroundColor: "#e1e1f3 !important",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              color: "text.primary",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell": {
              borderBottomColor: "primary.light",
            },
          }}
          loading={loader}
          hideFooter
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
}
